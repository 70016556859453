// TODO: ここにあるべきではない関数がいくつかあるので適切な場所に移動する & なんの関数かコメントを書く 2023/07/23
import dayjs from "dayjs"
import { CartItem } from "@tential/ec-gql-schema/models/order"
// import AmazonPayClient from "../AmazonPay/frontend"
// import { CartStateKey, CartStateType, ToastedStateKey, ToastedStateType } from "~/state/sessionStorage"
// import { getAmazonPayConfig } from "../AmazonPay/common"
// import { CartItem } from "~/types/type.generated"

export const getOrderNewUrl = (): string => {
  return `/order/new`
}

export const format2CartItem = (_cartItem: CartItem): CartItem => {
  const cartItem: CartItem = {
    product_id: _cartItem.product_id,
    product_name: _cartItem?.product_name ? _cartItem.product_name : "",
    sku_id: _cartItem.sku_id,
    sku_name: _cartItem.sku_name,
    site_id: _cartItem.site_id,
    droped_unixtime: dayjs().unix() * 1000,
    is_reservation: _cartItem.is_reservation,
    material: _cartItem?.material ? _cartItem.material : "",
    gift: _cartItem.gift,
    is_bundled_wrapping: _cartItem.is_bundled_wrapping,
    is_wrapping_product: _cartItem.is_wrapping_product,
    wrapping_sku_id: _cartItem.wrapping_sku_id,
  }
  if (_cartItem.net_stock_quantity != null) cartItem.net_stock_quantity = _cartItem.net_stock_quantity
  if (_cartItem.net_stock_quantity_limit != null) cartItem.net_stock_quantity_limit = _cartItem.net_stock_quantity_limit

  return cartItem
}
