import { default as _91productSlug_93D6N0oPW22rMeta } from "/app/apps/consumer/pages/[categorySlug]/[subCategorySlug]/[productSlug].vue?macro=true";
import { default as indexZ6vz7G03wyMeta } from "/app/apps/consumer/pages/[categorySlug]/[subCategorySlug]/index.vue?macro=true";
import { default as index4znPcCpuxzMeta } from "/app/apps/consumer/pages/[categorySlug]/index.vue?macro=true";
import { default as browsing_historyfEETrwom6CMeta } from "/app/apps/consumer/pages/browsing_history.vue?macro=true";
import { default as _91pageSlug_93JfQqF8dMzaMeta } from "/app/apps/consumer/pages/campaign/[pageSlug].vue?macro=true";
import { default as indexudKP5yrL9ZMeta } from "/app/apps/consumer/pages/campaign/index.vue?macro=true";
import { default as change_emailaucR2jN7rYMeta } from "/app/apps/consumer/pages/change_email.vue?macro=true";
import { default as confirmFHbfHMGyqUMeta } from "/app/apps/consumer/pages/contact/confirm.vue?macro=true";
import { default as indexDR9mihTKwvMeta } from "/app/apps/consumer/pages/contact/index.vue?macro=true";
import { default as thankyoueld0PwLbj5Meta } from "/app/apps/consumer/pages/contact/thankyou.vue?macro=true";
import { default as delivery_45placement_45term4z1ZbbSle3Meta } from "/app/apps/consumer/pages/delivery-placement-term.vue?macro=true";
import { default as _91pageSubSlug_93Glpye3yK9eMeta } from "/app/apps/consumer/pages/features/[pageSlug]/[pageSubSlug].vue?macro=true";
import { default as indexeTvIxapgRjMeta } from "/app/apps/consumer/pages/features/[pageSlug]/index.vue?macro=true";
import { default as indexfdqCZP5mNgMeta } from "/app/apps/consumer/pages/features/index.vue?macro=true";
import { default as motherandfather2024I7HK7iF8AHMeta } from "/app/apps/consumer/pages/features/motherandfather2024.vue?macro=true";
import { default as general_45medical_45devices8jJvaCEbb1Meta } from "/app/apps/consumer/pages/general-medical-devices.vue?macro=true";
import { default as get_couponzsprtPVbv9Meta } from "/app/apps/consumer/pages/get_coupon.vue?macro=true";
import { default as completevtfD19scSdMeta } from "/app/apps/consumer/pages/gift/[eGiftId]/complete.vue?macro=true";
import { default as confirmJt0cwdI8bpMeta } from "/app/apps/consumer/pages/gift/[eGiftId]/confirm.vue?macro=true";
import { default as indexXYNGkM2yk9Meta } from "/app/apps/consumer/pages/gift/[eGiftId]/index.vue?macro=true";
import { default as select61MiA6dOOMMeta } from "/app/apps/consumer/pages/gift/[eGiftId]/select.vue?macro=true";
import { default as shippingtgIDmhvC1EMeta } from "/app/apps/consumer/pages/gift/[eGiftId]/shipping.vue?macro=true";
import { default as tential_clubLe5PMZNJGBMeta } from "/app/apps/consumer/pages/guide/tential_club.vue?macro=true";
import { default as healthcheckzqdHXSkfmoMeta } from "/app/apps/consumer/pages/healthcheck.vue?macro=true";
import { default as indexpTpXLNx0dJMeta } from "/app/apps/consumer/pages/index.vue?macro=true";
import { default as index88iHIf02u8Meta } from "/app/apps/consumer/pages/interviews/[pageSlug]/index.vue?macro=true";
import { default as indexM4Vc8kxjCkMeta } from "/app/apps/consumer/pages/interviews/index.vue?macro=true";
import { default as _91journalSlug_93GRvYmsvzBnMeta } from "/app/apps/consumer/pages/journals/[category]/[subcategory]/[journalSlug].vue?macro=true";
import { default as indexYr2O3zgHvKMeta } from "/app/apps/consumer/pages/journals/[category]/[subcategory]/index.vue?macro=true";
import { default as index8vJLV0T6guMeta } from "/app/apps/consumer/pages/journals/index.vue?macro=true";
import { default as journalGgvlUUvei9Meta } from "/app/apps/consumer/pages/journals/journal.vue?macro=true";
import { default as searchFXLLBEkfjSMeta } from "/app/apps/consumer/pages/journals/search.vue?macro=true";
import { default as law_infozkZsgc6gjCMeta } from "/app/apps/consumer/pages/law_info.vue?macro=true";
import { default as _91pageSlug_93SlLOZXaPgKMeta } from "/app/apps/consumer/pages/lp/[pageSlug].vue?macro=true";
import { default as address_editOnuf1ygiV4Meta } from "/app/apps/consumer/pages/mypage/address_edit.vue?macro=true";
import { default as exchangeV5QaG6jzY5Meta } from "/app/apps/consumer/pages/mypage/coupons/exchange.vue?macro=true";
import { default as index1L21mcXzp0Meta } from "/app/apps/consumer/pages/mypage/coupons/index.vue?macro=true";
import { default as edit_emailodNbyaPWYJMeta } from "/app/apps/consumer/pages/mypage/edit_email.vue?macro=true";
import { default as edittUQatyPZ95Meta } from "/app/apps/consumer/pages/mypage/edit.vue?macro=true";
import { default as favorites0dJIefTLFxMeta } from "/app/apps/consumer/pages/mypage/favorites.vue?macro=true";
import { default as indexjp2WIA4qTJMeta } from "/app/apps/consumer/pages/mypage/index.vue?macro=true";
import { default as actionSL4rPXXDLDMeta } from "/app/apps/consumer/pages/mypage/mile/action.vue?macro=true";
import { default as history8Tki57fEuBMeta } from "/app/apps/consumer/pages/mypage/mile/history.vue?macro=true";
import { default as _91orderId_93LRJwu2LdxYMeta } from "/app/apps/consumer/pages/mypage/orders/[orderId].vue?macro=true";
import { default as indexeSkywGyv8CMeta } from "/app/apps/consumer/pages/mypage/orders/index.vue?macro=true";
import { default as password_edit9CSbVT52xiMeta } from "/app/apps/consumer/pages/mypage/password_edit.vue?macro=true";
import { default as purchase_history2N4agyzh1yMeta } from "/app/apps/consumer/pages/mypage/purchase_history.vue?macro=true";
import { default as completer7rIIrFS3JMeta } from "/app/apps/consumer/pages/mypage/review/[skuId]/complete.vue?macro=true";
import { default as confirmZIDLuucqyIMeta } from "/app/apps/consumer/pages/mypage/review/[skuId]/confirm.vue?macro=true";
import { default as new5A5g18SHnkMeta } from "/app/apps/consumer/pages/mypage/review/[skuId]/new.vue?macro=true";
import { default as done2BaxUqnQdUMeta } from "/app/apps/consumer/pages/mypage/withdrawal/done.vue?macro=true";
import { default as index4vVA6f4NMlMeta } from "/app/apps/consumer/pages/mypage/withdrawal/index.vue?macro=true";
import { default as indexaywdSnNylPMeta } from "/app/apps/consumer/pages/news/[newsSlug]/index.vue?macro=true";
import { default as index3XqzWBNDxrMeta } from "/app/apps/consumer/pages/news/index.vue?macro=true";
import { default as apay_45sessionSOvB9GZfmSMeta } from "/app/apps/consumer/pages/order/apay-session.vue?macro=true";
import { default as newuCh6rEOnA5Meta } from "/app/apps/consumer/pages/order/new.vue?macro=true";
import { default as thankyouHXf7yhBCYuMeta } from "/app/apps/consumer/pages/order/thankyou.vue?macro=true";
import { default as _91pageSlug_93nIDJK5jg1gMeta } from "/app/apps/consumer/pages/page/[pageSlug].vue?macro=true";
import { default as password_forgetEYiZRK7juoMeta } from "/app/apps/consumer/pages/password_forget.vue?macro=true";
import { default as password_resetPQPMbnKX2HMeta } from "/app/apps/consumer/pages/password_reset.vue?macro=true";
import { default as _91productSlug_93rpcTLlN0baMeta } from "/app/apps/consumer/pages/products/[productSlug].vue?macro=true";
import { default as indexj214DueTMMMeta } from "/app/apps/consumer/pages/products/index.vue?macro=true";
import { default as qr_readerOMRmofWOELMeta } from "/app/apps/consumer/pages/qr_reader.vue?macro=true";
import { default as return_or_exchangeddoFJbAdqLMeta } from "/app/apps/consumer/pages/return_or_exchange.vue?macro=true";
import { default as _91storeId_93ZDPz2ie4ZkMeta } from "/app/apps/consumer/pages/shop/detail/[storeId].vue?macro=true";
import { default as indexWObD3ZK5z2Meta } from "/app/apps/consumer/pages/shop/index.vue?macro=true";
import { default as officialgV9qWR6hkFMeta } from "/app/apps/consumer/pages/shop/official.vue?macro=true";
import { default as sign_inw42XhutLfTMeta } from "/app/apps/consumer/pages/sign_in.vue?macro=true";
import { default as sign_upMJTQpoXmm9Meta } from "/app/apps/consumer/pages/sign_up.vue?macro=true";
import { default as storyWlDtmP3W1CMeta } from "/app/apps/consumer/pages/story.vue?macro=true";
import { default as indexzXFaPO8mfEMeta } from "/app/apps/consumer/pages/support/index.vue?macro=true";
import { default as indexMnQTLUxDjsMeta } from "/app/apps/consumer/pages/survey_question/[surveyQuestionId]/index.vue?macro=true";
import { default as thankyouEsTMJfsr8rMeta } from "/app/apps/consumer/pages/survey_question/[surveyQuestionId]/thankyou.vue?macro=true";
import { default as termsaxTuWHXQP9Meta } from "/app/apps/consumer/pages/terms.vue?macro=true";
import { default as uiFc2BIumE83Meta } from "/app/apps/consumer/pages/ui.vue?macro=true";
export default [
  {
    name: _91productSlug_93D6N0oPW22rMeta?.name ?? "categorySlug-subCategorySlug-productSlug",
    path: _91productSlug_93D6N0oPW22rMeta?.path ?? "/:categorySlug()/:subCategorySlug()/:productSlug()",
    meta: _91productSlug_93D6N0oPW22rMeta || {},
    alias: _91productSlug_93D6N0oPW22rMeta?.alias || [],
    redirect: _91productSlug_93D6N0oPW22rMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/[categorySlug]/[subCategorySlug]/[productSlug].vue").then(m => m.default || m)
  },
  {
    name: indexZ6vz7G03wyMeta?.name ?? "categorySlug-subCategorySlug",
    path: indexZ6vz7G03wyMeta?.path ?? "/:categorySlug()/:subCategorySlug()",
    meta: indexZ6vz7G03wyMeta || {},
    alias: indexZ6vz7G03wyMeta?.alias || [],
    redirect: indexZ6vz7G03wyMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/[categorySlug]/[subCategorySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: index4znPcCpuxzMeta?.name ?? "categorySlug",
    path: index4znPcCpuxzMeta?.path ?? "/:categorySlug()",
    meta: index4znPcCpuxzMeta || {},
    alias: index4znPcCpuxzMeta?.alias || [],
    redirect: index4znPcCpuxzMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/[categorySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: browsing_historyfEETrwom6CMeta?.name ?? "browsing_history",
    path: browsing_historyfEETrwom6CMeta?.path ?? "/browsing_history",
    meta: browsing_historyfEETrwom6CMeta || {},
    alias: browsing_historyfEETrwom6CMeta?.alias || [],
    redirect: browsing_historyfEETrwom6CMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/browsing_history.vue").then(m => m.default || m)
  },
  {
    name: _91pageSlug_93JfQqF8dMzaMeta?.name ?? "campaign-pageSlug",
    path: _91pageSlug_93JfQqF8dMzaMeta?.path ?? "/campaign/:pageSlug()",
    meta: _91pageSlug_93JfQqF8dMzaMeta || {},
    alias: _91pageSlug_93JfQqF8dMzaMeta?.alias || [],
    redirect: _91pageSlug_93JfQqF8dMzaMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/campaign/[pageSlug].vue").then(m => m.default || m)
  },
  {
    name: indexudKP5yrL9ZMeta?.name ?? "campaign",
    path: indexudKP5yrL9ZMeta?.path ?? "/campaign",
    meta: indexudKP5yrL9ZMeta || {},
    alias: indexudKP5yrL9ZMeta?.alias || [],
    redirect: indexudKP5yrL9ZMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/campaign/index.vue").then(m => m.default || m)
  },
  {
    name: change_emailaucR2jN7rYMeta?.name ?? "change_email",
    path: change_emailaucR2jN7rYMeta?.path ?? "/change_email",
    meta: change_emailaucR2jN7rYMeta || {},
    alias: change_emailaucR2jN7rYMeta?.alias || [],
    redirect: change_emailaucR2jN7rYMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/change_email.vue").then(m => m.default || m)
  },
  {
    name: confirmFHbfHMGyqUMeta?.name ?? "contact-confirm",
    path: confirmFHbfHMGyqUMeta?.path ?? "/contact/confirm",
    meta: confirmFHbfHMGyqUMeta || {},
    alias: confirmFHbfHMGyqUMeta?.alias || [],
    redirect: confirmFHbfHMGyqUMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/contact/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexDR9mihTKwvMeta?.name ?? "contact",
    path: indexDR9mihTKwvMeta?.path ?? "/contact",
    meta: indexDR9mihTKwvMeta || {},
    alias: indexDR9mihTKwvMeta?.alias || [],
    redirect: indexDR9mihTKwvMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: thankyoueld0PwLbj5Meta?.name ?? "contact-thankyou",
    path: thankyoueld0PwLbj5Meta?.path ?? "/contact/thankyou",
    meta: thankyoueld0PwLbj5Meta || {},
    alias: thankyoueld0PwLbj5Meta?.alias || [],
    redirect: thankyoueld0PwLbj5Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/contact/thankyou.vue").then(m => m.default || m)
  },
  {
    name: delivery_45placement_45term4z1ZbbSle3Meta?.name ?? "delivery-placement-term",
    path: delivery_45placement_45term4z1ZbbSle3Meta?.path ?? "/delivery-placement-term",
    meta: delivery_45placement_45term4z1ZbbSle3Meta || {},
    alias: delivery_45placement_45term4z1ZbbSle3Meta?.alias || [],
    redirect: delivery_45placement_45term4z1ZbbSle3Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/delivery-placement-term.vue").then(m => m.default || m)
  },
  {
    name: _91pageSubSlug_93Glpye3yK9eMeta?.name ?? "features-pageSlug-pageSubSlug",
    path: _91pageSubSlug_93Glpye3yK9eMeta?.path ?? "/features/:pageSlug()/:pageSubSlug()",
    meta: _91pageSubSlug_93Glpye3yK9eMeta || {},
    alias: _91pageSubSlug_93Glpye3yK9eMeta?.alias || [],
    redirect: _91pageSubSlug_93Glpye3yK9eMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/features/[pageSlug]/[pageSubSlug].vue").then(m => m.default || m)
  },
  {
    name: indexeTvIxapgRjMeta?.name ?? "features-pageSlug",
    path: indexeTvIxapgRjMeta?.path ?? "/features/:pageSlug()",
    meta: indexeTvIxapgRjMeta || {},
    alias: indexeTvIxapgRjMeta?.alias || [],
    redirect: indexeTvIxapgRjMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/features/[pageSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfdqCZP5mNgMeta?.name ?? "features",
    path: indexfdqCZP5mNgMeta?.path ?? "/features",
    meta: indexfdqCZP5mNgMeta || {},
    alias: indexfdqCZP5mNgMeta?.alias || [],
    redirect: indexfdqCZP5mNgMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: motherandfather2024I7HK7iF8AHMeta?.name ?? "features-motherandfather2024",
    path: motherandfather2024I7HK7iF8AHMeta?.path ?? "/features/motherandfather2024",
    meta: motherandfather2024I7HK7iF8AHMeta || {},
    alias: motherandfather2024I7HK7iF8AHMeta?.alias || [],
    redirect: motherandfather2024I7HK7iF8AHMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/features/motherandfather2024.vue").then(m => m.default || m)
  },
  {
    name: general_45medical_45devices8jJvaCEbb1Meta?.name ?? "general-medical-devices",
    path: general_45medical_45devices8jJvaCEbb1Meta?.path ?? "/general-medical-devices",
    meta: general_45medical_45devices8jJvaCEbb1Meta || {},
    alias: general_45medical_45devices8jJvaCEbb1Meta?.alias || [],
    redirect: general_45medical_45devices8jJvaCEbb1Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/general-medical-devices.vue").then(m => m.default || m)
  },
  {
    name: get_couponzsprtPVbv9Meta?.name ?? "get_coupon",
    path: get_couponzsprtPVbv9Meta?.path ?? "/get_coupon",
    meta: get_couponzsprtPVbv9Meta || {},
    alias: get_couponzsprtPVbv9Meta?.alias || [],
    redirect: get_couponzsprtPVbv9Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/get_coupon.vue").then(m => m.default || m)
  },
  {
    name: completevtfD19scSdMeta?.name ?? "gift-eGiftId-complete",
    path: completevtfD19scSdMeta?.path ?? "/gift/:eGiftId()/complete",
    meta: completevtfD19scSdMeta || {},
    alias: completevtfD19scSdMeta?.alias || [],
    redirect: completevtfD19scSdMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/gift/[eGiftId]/complete.vue").then(m => m.default || m)
  },
  {
    name: confirmJt0cwdI8bpMeta?.name ?? "gift-eGiftId-confirm",
    path: confirmJt0cwdI8bpMeta?.path ?? "/gift/:eGiftId()/confirm",
    meta: confirmJt0cwdI8bpMeta || {},
    alias: confirmJt0cwdI8bpMeta?.alias || [],
    redirect: confirmJt0cwdI8bpMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/gift/[eGiftId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexXYNGkM2yk9Meta?.name ?? "gift-eGiftId",
    path: indexXYNGkM2yk9Meta?.path ?? "/gift/:eGiftId()",
    meta: indexXYNGkM2yk9Meta || {},
    alias: indexXYNGkM2yk9Meta?.alias || [],
    redirect: indexXYNGkM2yk9Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/gift/[eGiftId]/index.vue").then(m => m.default || m)
  },
  {
    name: select61MiA6dOOMMeta?.name ?? "gift-eGiftId-select",
    path: select61MiA6dOOMMeta?.path ?? "/gift/:eGiftId()/select",
    meta: select61MiA6dOOMMeta || {},
    alias: select61MiA6dOOMMeta?.alias || [],
    redirect: select61MiA6dOOMMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/gift/[eGiftId]/select.vue").then(m => m.default || m)
  },
  {
    name: shippingtgIDmhvC1EMeta?.name ?? "gift-eGiftId-shipping",
    path: shippingtgIDmhvC1EMeta?.path ?? "/gift/:eGiftId()/shipping",
    meta: shippingtgIDmhvC1EMeta || {},
    alias: shippingtgIDmhvC1EMeta?.alias || [],
    redirect: shippingtgIDmhvC1EMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/gift/[eGiftId]/shipping.vue").then(m => m.default || m)
  },
  {
    name: tential_clubLe5PMZNJGBMeta?.name ?? "guide-tential_club",
    path: tential_clubLe5PMZNJGBMeta?.path ?? "/guide/tential_club",
    meta: tential_clubLe5PMZNJGBMeta || {},
    alias: tential_clubLe5PMZNJGBMeta?.alias || [],
    redirect: tential_clubLe5PMZNJGBMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/guide/tential_club.vue").then(m => m.default || m)
  },
  {
    name: healthcheckzqdHXSkfmoMeta?.name ?? "healthcheck",
    path: healthcheckzqdHXSkfmoMeta?.path ?? "/healthcheck",
    meta: healthcheckzqdHXSkfmoMeta || {},
    alias: healthcheckzqdHXSkfmoMeta?.alias || [],
    redirect: healthcheckzqdHXSkfmoMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/healthcheck.vue").then(m => m.default || m)
  },
  {
    name: indexpTpXLNx0dJMeta?.name ?? "index",
    path: indexpTpXLNx0dJMeta?.path ?? "/",
    meta: indexpTpXLNx0dJMeta || {},
    alias: indexpTpXLNx0dJMeta?.alias || [],
    redirect: indexpTpXLNx0dJMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index88iHIf02u8Meta?.name ?? "interviews-pageSlug",
    path: index88iHIf02u8Meta?.path ?? "/interviews/:pageSlug()",
    meta: index88iHIf02u8Meta || {},
    alias: index88iHIf02u8Meta?.alias || [],
    redirect: index88iHIf02u8Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/interviews/[pageSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexM4Vc8kxjCkMeta?.name ?? "interviews",
    path: indexM4Vc8kxjCkMeta?.path ?? "/interviews",
    meta: indexM4Vc8kxjCkMeta || {},
    alias: indexM4Vc8kxjCkMeta?.alias || [],
    redirect: indexM4Vc8kxjCkMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/interviews/index.vue").then(m => m.default || m)
  },
  {
    name: _91journalSlug_93GRvYmsvzBnMeta?.name ?? "journals-category-subcategory-journalSlug",
    path: _91journalSlug_93GRvYmsvzBnMeta?.path ?? "/journals/:category()/:subcategory()/:journalSlug()",
    meta: _91journalSlug_93GRvYmsvzBnMeta || {},
    alias: _91journalSlug_93GRvYmsvzBnMeta?.alias || [],
    redirect: _91journalSlug_93GRvYmsvzBnMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/journals/[category]/[subcategory]/[journalSlug].vue").then(m => m.default || m)
  },
  {
    name: indexYr2O3zgHvKMeta?.name ?? "journals-category-subcategory",
    path: indexYr2O3zgHvKMeta?.path ?? "/journals/:category()/:subcategory()",
    meta: indexYr2O3zgHvKMeta || {},
    alias: indexYr2O3zgHvKMeta?.alias || [],
    redirect: indexYr2O3zgHvKMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/journals/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: index8vJLV0T6guMeta?.name ?? "journals",
    path: index8vJLV0T6guMeta?.path ?? "/journals",
    meta: index8vJLV0T6guMeta || {},
    alias: index8vJLV0T6guMeta?.alias || [],
    redirect: index8vJLV0T6guMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/journals/index.vue").then(m => m.default || m)
  },
  {
    name: journalGgvlUUvei9Meta?.name ?? "journals-journal",
    path: journalGgvlUUvei9Meta?.path ?? "/journals/journal",
    meta: journalGgvlUUvei9Meta || {},
    alias: journalGgvlUUvei9Meta?.alias || [],
    redirect: journalGgvlUUvei9Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/journals/journal.vue").then(m => m.default || m)
  },
  {
    name: searchFXLLBEkfjSMeta?.name ?? "journals-search",
    path: searchFXLLBEkfjSMeta?.path ?? "/journals/search",
    meta: searchFXLLBEkfjSMeta || {},
    alias: searchFXLLBEkfjSMeta?.alias || [],
    redirect: searchFXLLBEkfjSMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/journals/search.vue").then(m => m.default || m)
  },
  {
    name: law_infozkZsgc6gjCMeta?.name ?? "law_info",
    path: law_infozkZsgc6gjCMeta?.path ?? "/law_info",
    meta: law_infozkZsgc6gjCMeta || {},
    alias: law_infozkZsgc6gjCMeta?.alias || [],
    redirect: law_infozkZsgc6gjCMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/law_info.vue").then(m => m.default || m)
  },
  {
    name: _91pageSlug_93SlLOZXaPgKMeta?.name ?? "lp-pageSlug",
    path: _91pageSlug_93SlLOZXaPgKMeta?.path ?? "/lp/:pageSlug()",
    meta: _91pageSlug_93SlLOZXaPgKMeta || {},
    alias: _91pageSlug_93SlLOZXaPgKMeta?.alias || [],
    redirect: _91pageSlug_93SlLOZXaPgKMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/lp/[pageSlug].vue").then(m => m.default || m)
  },
  {
    name: address_editOnuf1ygiV4Meta?.name ?? "mypage-address_edit",
    path: address_editOnuf1ygiV4Meta?.path ?? "/mypage/address_edit",
    meta: address_editOnuf1ygiV4Meta || {},
    alias: address_editOnuf1ygiV4Meta?.alias || [],
    redirect: address_editOnuf1ygiV4Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/address_edit.vue").then(m => m.default || m)
  },
  {
    name: exchangeV5QaG6jzY5Meta?.name ?? "mypage-coupons-exchange",
    path: exchangeV5QaG6jzY5Meta?.path ?? "/mypage/coupons/exchange",
    meta: exchangeV5QaG6jzY5Meta || {},
    alias: exchangeV5QaG6jzY5Meta?.alias || [],
    redirect: exchangeV5QaG6jzY5Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/coupons/exchange.vue").then(m => m.default || m)
  },
  {
    name: index1L21mcXzp0Meta?.name ?? "mypage-coupons",
    path: index1L21mcXzp0Meta?.path ?? "/mypage/coupons",
    meta: index1L21mcXzp0Meta || {},
    alias: index1L21mcXzp0Meta?.alias || [],
    redirect: index1L21mcXzp0Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: edit_emailodNbyaPWYJMeta?.name ?? "mypage-edit_email",
    path: edit_emailodNbyaPWYJMeta?.path ?? "/mypage/edit_email",
    meta: edit_emailodNbyaPWYJMeta || {},
    alias: edit_emailodNbyaPWYJMeta?.alias || [],
    redirect: edit_emailodNbyaPWYJMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/edit_email.vue").then(m => m.default || m)
  },
  {
    name: edittUQatyPZ95Meta?.name ?? "mypage-edit",
    path: edittUQatyPZ95Meta?.path ?? "/mypage/edit",
    meta: edittUQatyPZ95Meta || {},
    alias: edittUQatyPZ95Meta?.alias || [],
    redirect: edittUQatyPZ95Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/edit.vue").then(m => m.default || m)
  },
  {
    name: favorites0dJIefTLFxMeta?.name ?? "mypage-favorites",
    path: favorites0dJIefTLFxMeta?.path ?? "/mypage/favorites",
    meta: favorites0dJIefTLFxMeta || {},
    alias: favorites0dJIefTLFxMeta?.alias || [],
    redirect: favorites0dJIefTLFxMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexjp2WIA4qTJMeta?.name ?? "mypage",
    path: indexjp2WIA4qTJMeta?.path ?? "/mypage",
    meta: indexjp2WIA4qTJMeta || {},
    alias: indexjp2WIA4qTJMeta?.alias || [],
    redirect: indexjp2WIA4qTJMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: actionSL4rPXXDLDMeta?.name ?? "mypage-mile-action",
    path: actionSL4rPXXDLDMeta?.path ?? "/mypage/mile/action",
    meta: actionSL4rPXXDLDMeta || {},
    alias: actionSL4rPXXDLDMeta?.alias || [],
    redirect: actionSL4rPXXDLDMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/mile/action.vue").then(m => m.default || m)
  },
  {
    name: history8Tki57fEuBMeta?.name ?? "mypage-mile-history",
    path: history8Tki57fEuBMeta?.path ?? "/mypage/mile/history",
    meta: history8Tki57fEuBMeta || {},
    alias: history8Tki57fEuBMeta?.alias || [],
    redirect: history8Tki57fEuBMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/mile/history.vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93LRJwu2LdxYMeta?.name ?? "mypage-orders-orderId",
    path: _91orderId_93LRJwu2LdxYMeta?.path ?? "/mypage/orders/:orderId()",
    meta: _91orderId_93LRJwu2LdxYMeta || {},
    alias: _91orderId_93LRJwu2LdxYMeta?.alias || [],
    redirect: _91orderId_93LRJwu2LdxYMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: indexeSkywGyv8CMeta?.name ?? "mypage-orders",
    path: indexeSkywGyv8CMeta?.path ?? "/mypage/orders",
    meta: indexeSkywGyv8CMeta || {},
    alias: indexeSkywGyv8CMeta?.alias || [],
    redirect: indexeSkywGyv8CMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/orders/index.vue").then(m => m.default || m)
  },
  {
    name: password_edit9CSbVT52xiMeta?.name ?? "mypage-password_edit",
    path: password_edit9CSbVT52xiMeta?.path ?? "/mypage/password_edit",
    meta: password_edit9CSbVT52xiMeta || {},
    alias: password_edit9CSbVT52xiMeta?.alias || [],
    redirect: password_edit9CSbVT52xiMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/password_edit.vue").then(m => m.default || m)
  },
  {
    name: purchase_history2N4agyzh1yMeta?.name ?? "mypage-purchase_history",
    path: purchase_history2N4agyzh1yMeta?.path ?? "/mypage/purchase_history",
    meta: purchase_history2N4agyzh1yMeta || {},
    alias: purchase_history2N4agyzh1yMeta?.alias || [],
    redirect: purchase_history2N4agyzh1yMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/purchase_history.vue").then(m => m.default || m)
  },
  {
    name: completer7rIIrFS3JMeta?.name ?? "mypage-review-skuId-complete",
    path: completer7rIIrFS3JMeta?.path ?? "/mypage/review/:skuId()/complete",
    meta: completer7rIIrFS3JMeta || {},
    alias: completer7rIIrFS3JMeta?.alias || [],
    redirect: completer7rIIrFS3JMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/review/[skuId]/complete.vue").then(m => m.default || m)
  },
  {
    name: confirmZIDLuucqyIMeta?.name ?? "mypage-review-skuId-confirm",
    path: confirmZIDLuucqyIMeta?.path ?? "/mypage/review/:skuId()/confirm",
    meta: confirmZIDLuucqyIMeta || {},
    alias: confirmZIDLuucqyIMeta?.alias || [],
    redirect: confirmZIDLuucqyIMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/review/[skuId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: new5A5g18SHnkMeta?.name ?? "mypage-review-skuId-new",
    path: new5A5g18SHnkMeta?.path ?? "/mypage/review/:skuId()/new",
    meta: new5A5g18SHnkMeta || {},
    alias: new5A5g18SHnkMeta?.alias || [],
    redirect: new5A5g18SHnkMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/review/[skuId]/new.vue").then(m => m.default || m)
  },
  {
    name: done2BaxUqnQdUMeta?.name ?? "mypage-withdrawal-done",
    path: done2BaxUqnQdUMeta?.path ?? "/mypage/withdrawal/done",
    meta: done2BaxUqnQdUMeta || {},
    alias: done2BaxUqnQdUMeta?.alias || [],
    redirect: done2BaxUqnQdUMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/withdrawal/done.vue").then(m => m.default || m)
  },
  {
    name: index4vVA6f4NMlMeta?.name ?? "mypage-withdrawal",
    path: index4vVA6f4NMlMeta?.path ?? "/mypage/withdrawal",
    meta: index4vVA6f4NMlMeta || {},
    alias: index4vVA6f4NMlMeta?.alias || [],
    redirect: index4vVA6f4NMlMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/mypage/withdrawal/index.vue").then(m => m.default || m)
  },
  {
    name: indexaywdSnNylPMeta?.name ?? "news-newsSlug",
    path: indexaywdSnNylPMeta?.path ?? "/news/:newsSlug()",
    meta: indexaywdSnNylPMeta || {},
    alias: indexaywdSnNylPMeta?.alias || [],
    redirect: indexaywdSnNylPMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/news/[newsSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: index3XqzWBNDxrMeta?.name ?? "news",
    path: index3XqzWBNDxrMeta?.path ?? "/news",
    meta: index3XqzWBNDxrMeta || {},
    alias: index3XqzWBNDxrMeta?.alias || [],
    redirect: index3XqzWBNDxrMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: apay_45sessionSOvB9GZfmSMeta?.name ?? "order-apay-session",
    path: apay_45sessionSOvB9GZfmSMeta?.path ?? "/order/apay-session",
    meta: apay_45sessionSOvB9GZfmSMeta || {},
    alias: apay_45sessionSOvB9GZfmSMeta?.alias || [],
    redirect: apay_45sessionSOvB9GZfmSMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/order/apay-session.vue").then(m => m.default || m)
  },
  {
    name: newuCh6rEOnA5Meta?.name ?? "order-new",
    path: newuCh6rEOnA5Meta?.path ?? "/order/new",
    meta: newuCh6rEOnA5Meta || {},
    alias: newuCh6rEOnA5Meta?.alias || [],
    redirect: newuCh6rEOnA5Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/order/new.vue").then(m => m.default || m)
  },
  {
    name: thankyouHXf7yhBCYuMeta?.name ?? "order-thankyou",
    path: thankyouHXf7yhBCYuMeta?.path ?? "/order/thankyou",
    meta: thankyouHXf7yhBCYuMeta || {},
    alias: thankyouHXf7yhBCYuMeta?.alias || [],
    redirect: thankyouHXf7yhBCYuMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/order/thankyou.vue").then(m => m.default || m)
  },
  {
    name: _91pageSlug_93nIDJK5jg1gMeta?.name ?? "page-pageSlug",
    path: _91pageSlug_93nIDJK5jg1gMeta?.path ?? "/page/:pageSlug()",
    meta: _91pageSlug_93nIDJK5jg1gMeta || {},
    alias: _91pageSlug_93nIDJK5jg1gMeta?.alias || [],
    redirect: _91pageSlug_93nIDJK5jg1gMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/page/[pageSlug].vue").then(m => m.default || m)
  },
  {
    name: password_forgetEYiZRK7juoMeta?.name ?? "password_forget",
    path: password_forgetEYiZRK7juoMeta?.path ?? "/password_forget",
    meta: password_forgetEYiZRK7juoMeta || {},
    alias: password_forgetEYiZRK7juoMeta?.alias || [],
    redirect: password_forgetEYiZRK7juoMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/password_forget.vue").then(m => m.default || m)
  },
  {
    name: password_resetPQPMbnKX2HMeta?.name ?? "password_reset",
    path: password_resetPQPMbnKX2HMeta?.path ?? "/password_reset",
    meta: password_resetPQPMbnKX2HMeta || {},
    alias: password_resetPQPMbnKX2HMeta?.alias || [],
    redirect: password_resetPQPMbnKX2HMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/password_reset.vue").then(m => m.default || m)
  },
  {
    name: _91productSlug_93rpcTLlN0baMeta?.name ?? "products-productSlug",
    path: _91productSlug_93rpcTLlN0baMeta?.path ?? "/products/:productSlug()",
    meta: _91productSlug_93rpcTLlN0baMeta || {},
    alias: _91productSlug_93rpcTLlN0baMeta?.alias || [],
    redirect: _91productSlug_93rpcTLlN0baMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/products/[productSlug].vue").then(m => m.default || m)
  },
  {
    name: indexj214DueTMMMeta?.name ?? "products",
    path: indexj214DueTMMMeta?.path ?? "/products",
    meta: indexj214DueTMMMeta || {},
    alias: indexj214DueTMMMeta?.alias || [],
    redirect: indexj214DueTMMMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: qr_readerOMRmofWOELMeta?.name ?? "qr_reader",
    path: qr_readerOMRmofWOELMeta?.path ?? "/qr_reader",
    meta: qr_readerOMRmofWOELMeta || {},
    alias: qr_readerOMRmofWOELMeta?.alias || [],
    redirect: qr_readerOMRmofWOELMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/qr_reader.vue").then(m => m.default || m)
  },
  {
    name: return_or_exchangeddoFJbAdqLMeta?.name ?? "return_or_exchange",
    path: return_or_exchangeddoFJbAdqLMeta?.path ?? "/return_or_exchange",
    meta: return_or_exchangeddoFJbAdqLMeta || {},
    alias: return_or_exchangeddoFJbAdqLMeta?.alias || [],
    redirect: return_or_exchangeddoFJbAdqLMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/return_or_exchange.vue").then(m => m.default || m)
  },
  {
    name: _91storeId_93ZDPz2ie4ZkMeta?.name ?? "shop-detail-storeId",
    path: _91storeId_93ZDPz2ie4ZkMeta?.path ?? "/shop/detail/:storeId()",
    meta: _91storeId_93ZDPz2ie4ZkMeta || {},
    alias: _91storeId_93ZDPz2ie4ZkMeta?.alias || [],
    redirect: _91storeId_93ZDPz2ie4ZkMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/shop/detail/[storeId].vue").then(m => m.default || m)
  },
  {
    name: indexWObD3ZK5z2Meta?.name ?? "shop",
    path: indexWObD3ZK5z2Meta?.path ?? "/shop",
    meta: indexWObD3ZK5z2Meta || {},
    alias: indexWObD3ZK5z2Meta?.alias || [],
    redirect: indexWObD3ZK5z2Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: officialgV9qWR6hkFMeta?.name ?? "shop-official",
    path: officialgV9qWR6hkFMeta?.path ?? "/shop/official",
    meta: officialgV9qWR6hkFMeta || {},
    alias: officialgV9qWR6hkFMeta?.alias || [],
    redirect: officialgV9qWR6hkFMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/shop/official.vue").then(m => m.default || m)
  },
  {
    name: sign_inw42XhutLfTMeta?.name ?? "sign_in",
    path: sign_inw42XhutLfTMeta?.path ?? "/sign_in",
    meta: sign_inw42XhutLfTMeta || {},
    alias: sign_inw42XhutLfTMeta?.alias || [],
    redirect: sign_inw42XhutLfTMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/sign_in.vue").then(m => m.default || m)
  },
  {
    name: sign_upMJTQpoXmm9Meta?.name ?? "sign_up",
    path: sign_upMJTQpoXmm9Meta?.path ?? "/sign_up",
    meta: sign_upMJTQpoXmm9Meta || {},
    alias: sign_upMJTQpoXmm9Meta?.alias || [],
    redirect: sign_upMJTQpoXmm9Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/sign_up.vue").then(m => m.default || m)
  },
  {
    name: storyWlDtmP3W1CMeta?.name ?? "story",
    path: storyWlDtmP3W1CMeta?.path ?? "/story",
    meta: storyWlDtmP3W1CMeta || {},
    alias: storyWlDtmP3W1CMeta?.alias || [],
    redirect: storyWlDtmP3W1CMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/story.vue").then(m => m.default || m)
  },
  {
    name: indexzXFaPO8mfEMeta?.name ?? "support",
    path: indexzXFaPO8mfEMeta?.path ?? "/support",
    meta: indexzXFaPO8mfEMeta || {},
    alias: indexzXFaPO8mfEMeta?.alias || [],
    redirect: indexzXFaPO8mfEMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexMnQTLUxDjsMeta?.name ?? "survey_question-surveyQuestionId",
    path: indexMnQTLUxDjsMeta?.path ?? "/survey_question/:surveyQuestionId()",
    meta: indexMnQTLUxDjsMeta || {},
    alias: indexMnQTLUxDjsMeta?.alias || [],
    redirect: indexMnQTLUxDjsMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/survey_question/[surveyQuestionId]/index.vue").then(m => m.default || m)
  },
  {
    name: thankyouEsTMJfsr8rMeta?.name ?? "survey_question-surveyQuestionId-thankyou",
    path: thankyouEsTMJfsr8rMeta?.path ?? "/survey_question/:surveyQuestionId()/thankyou",
    meta: thankyouEsTMJfsr8rMeta || {},
    alias: thankyouEsTMJfsr8rMeta?.alias || [],
    redirect: thankyouEsTMJfsr8rMeta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/survey_question/[surveyQuestionId]/thankyou.vue").then(m => m.default || m)
  },
  {
    name: termsaxTuWHXQP9Meta?.name ?? "terms",
    path: termsaxTuWHXQP9Meta?.path ?? "/terms",
    meta: termsaxTuWHXQP9Meta || {},
    alias: termsaxTuWHXQP9Meta?.alias || [],
    redirect: termsaxTuWHXQP9Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: uiFc2BIumE83Meta?.name ?? "ui",
    path: uiFc2BIumE83Meta?.path ?? "/ui",
    meta: uiFc2BIumE83Meta || {},
    alias: uiFc2BIumE83Meta?.alias || [],
    redirect: uiFc2BIumE83Meta?.redirect || undefined,
    component: () => import("/app/apps/consumer/pages/ui.vue").then(m => m.default || m)
  }
]