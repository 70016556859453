/* eslint-disable */
// @ts-nocheck
import * as Types from '~/types/type.generated';

import gql from 'graphql-tag';
import * as Urql from '@urql/vue';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const AdTrackingFragmentDoc = gql`
    fragment AdTracking on AdTracking {
  document_id
  site_id
  ad_id
  url
  created_unixtime
  updated_unixtime
}
    `;
export const AdvertiserFragmentDoc = gql`
    fragment Advertiser on Advertiser {
  document_id
  site_member_id
  site_id
  ad_url
  is_status
  created_unixtime
  updated_unixtime
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on Contact {
  document_id
  site_id
  name
  email
  type_id
  type_name
  category
  sub_category
  support_status
  product_name
  order_id
  content
  image_url
  image_urls
  reply_name
  reply_status
  local_user_id
  created_unixtime
  updated_unixtime
}
    `;
export const CouponFragmentFragmentDoc = gql`
    fragment CouponFragment on Coupon {
  use_loc
  is_status
  archived_unixtime
  can_combined_coupon
  code
  coupon_event
  coupon_text
  coupon_type
  created_unixtime
  discount_fixed_amount
  discount_method
  expiration_days
  document_id
  discount_rate
  expired_unixtime
  is_all_products
  is_archived
  is_exchange_coupon
  is_auto_coupon
  is_free_postage
  max_count
  min_price
  min_quantity
  name
  public_name
  site_id
  priority
  started_unixtime
  updated_unixtime
  patterns_ignore_sku_ids
  ignore_product_ids
  mile_expense
  patterns {
    category_ids
    min_quantity
    operand
    product_ids
    sku_ids
    target_table
    unit
  }
  use_loc
  mile_expense
}
    `;
export const CouponWithTargetProductNameFragmentDoc = gql`
    fragment CouponWithTargetProductName on CouponWithTargetProductNamesType {
  use_loc
  is_status
  archived_unixtime
  can_combined_coupon
  code
  coupon_event
  coupon_text
  coupon_type
  created_unixtime
  discount_fixed_amount
  discount_method
  expiration_days
  document_id
  discount_rate
  expired_unixtime
  is_all_products
  is_archived
  is_exchange_coupon
  is_auto_coupon
  is_free_postage
  max_count
  min_price
  min_quantity
  name
  public_name
  site_id
  priority
  started_unixtime
  updated_unixtime
  patterns_ignore_sku_ids
  ignore_product_ids
  mile_expense
  patterns {
    category_ids
    min_quantity
    operand
    product_ids
    sku_ids
    target_table
    unit
  }
  use_loc
  mile_expense
  target_product_names
}
    `;
export const CouponWithUserCouponIdFragmentDoc = gql`
    fragment CouponWithUserCouponId on CouponWithTargetProductNamesAndUserCouponIdType {
  is_status
  archived_unixtime
  can_combined_coupon
  code
  coupon_event
  coupon_text
  coupon_type
  created_unixtime
  discount_fixed_amount
  discount_method
  expiration_days
  document_id
  discount_rate
  expired_unixtime
  is_all_products
  is_archived
  is_exchange_coupon
  is_auto_coupon
  is_free_postage
  max_count
  min_price
  min_quantity
  name
  public_name
  site_id
  priority
  started_unixtime
  updated_unixtime
  patterns_ignore_sku_ids
  ignore_product_ids
  mile_expense
  patterns {
    category_ids
    min_quantity
    operand
    product_ids
    sku_ids
    target_table
    unit
  }
  user_coupon_id
  use_loc
  not_link_order_history_id
  target_product_names
}
    `;
export const CouponHistoryFragmentDoc = gql`
    fragment CouponHistory on CouponHistory {
  site_id
  user_id
  coupon_id
  document_id
  coupon_at_purchased {
    name
    text
    method
    is_auto
  }
}
    `;
export const EGiftFragmentDoc = gql`
    fragment EGift on EGift {
  document_id
  order_id
  message
  giver_name
  gift_type
  status
  token
  email
  stock_products {
    product_id
    sku_ids
  }
  expired_unixtime
  last_expired_unixtime
  created_unixtime
  updated_unixtime
}
    `;
export const EGiftWithOrderFragmentDoc = gql`
    fragment EGiftWithOrder on EGiftWithOrder {
  document_id
  order_id
  message
  giver_name
  created_unixtime
  updated_unixtime
  gift_type
  status
  token
  email
  stock_products {
    product_id
    sku_ids
  }
  expired_unixtime
  last_expired_unixtime
  order {
    document_id
    email
    user_id
    payment_type
    payment_status
    delivered_status
    purchased_site
    amount
    amount_total
    amount_captured
    charge_id
    is_logiless_registration
    stripe_payment_intent_id
    amazon_pay_session_id
    wms_integration_status
    total_details {
      amount_discount
      amount_shipping
      amount_tax
      amount_discount_exclude_tax
    }
    items {
      color
      consumption_tax_percentage
      droped_unixtime
      is_bundled_wrapping
      is_reservation
      is_wrapping_product
      wrapping_sku_id
      original_gift_ids
      price
      price_exclude_tax
      product_id
      product_name
      size
      sku_id
      sku_name
      gift {
        img
        message
        message_img
        message_price
        message_sku_code
        price
        product_name
        size
        sku_code
        sku_id
        sku_name
      }
    }
    details_by_item {
      sku_id
      subscription_item_id
      quantity
      price
      discount_price
    }
    user_info {
      tel
      name
      zip_code
      prefecture
      address_line1
      address_line2
    }
    address_info {
      tel
      name
      zip_code
      prefecture
      address_line1
      address_line2
    }
    shipping_info {
      shipping_referred_type
      delivery_company
      is_batch
      shipped_date
      shipped_number
      shipping_referred_date
      shipping_referred_time
      delivery_placement
    }
    refferal_query {
      ad_url
      to_url
    }
    traffic_query {
      full_path
    }
    created_unixtime
    updated_unixtime
  }
}
    `;
export const FavoriteFragmentDoc = gql`
    fragment Favorite on Favorite {
  document_id
  user_id
  sku_id
  product_id
  created_unixtime
  updated_unixtime
}
    `;
export const FavoriteDetailFragmentDoc = gql`
    fragment FavoriteDetail on Favorite {
  document_id
  user_id
  sku_id
  product_id
  created_unixtime
  updated_unixtime
  sku {
    document_id
    name
    size
    sku_code
    color
    color_code
    color_label
    chip_image_url
    compare_price
    product_id
    main_image
    main_image_content
    consumption_tax_percentage
    price
    site_id
    package_size
    is_reservation
    sub_images {
      content
      image_url
      is_first_view
      is_color_image
      display_order_number
      is_wrapping
    }
    is_set_sku
    is_status
    writer_user_id
    size_label
    display_order_number
    stock_quantity
    stock_quantity_limit
    net_stock_quantity
    net_stock_quantity_limit
    egift_stock_quantity_limit
    scm_type
    size_supplement
    is_restock_notice
    is_optional_shipping_supplement
    shipping_supplement
    maximum_wrapping_size
    minimum_wrapping_size
    sales_status
    jan_code
    management_jan_code
    is_offline
    offline_related_jan_code
    created_unixtime
    updated_unixtime
  }
  product {
    document_id
    name
    slug
    pass_query
    material
    is_status
    is_wrapping
    wrapping_sku_id
    is_not_individual_order
    order_quantity_limit
    order_quantity_limit_type
    explanation
    release_date
    writer_user_id
    product_category_ids
    recommend_product_ids
    related_products_ids
    size_range
    promotion_label_ids
    promotion_labels {
      label_name
      document_id
      display_order_number
      background_color
      label_color
    }
    functionality_label_ids
    functionality_labels {
      label_name
      document_id
      display_order_number
    }
    sex
    fabric
    season
    wear_category
    is_general_medical_device
    is_quasi_drug
    ranking_sale_order
    site_id
    is_gift
    cart_only_gift
    is_embroidery
    embroidery {
      embroidery_position_img
      embroidery_positions
    }
    color_type
    product_categories {
      document_id
      name
      slug
      parent_category_id
      not_display_search
      parent_category {
        name
        slug
      }
    }
    query_published_sites {
      kencoco
      affiliate_only
    }
    size_chart {
      pc_img_url
      sp_img_url
    }
    created_unixtime
    updated_unixtime
    display_priority
    keywords
    is_return_or_exchange
    not_display_product_list
    is_all_coupons_not_applicable
    is_not_display_stock
  }
}
    `;
export const FunctionalityLabelFragmentDoc = gql`
    fragment FunctionalityLabel on FunctionalityLabel {
  document_id
  label_name
  display_order_number
  is_status
  created_unixtime
  updated_unixtime
}
    `;
export const GiftPatternFragmentFragmentDoc = gql`
    fragment GiftPatternFragment on GiftPattern {
  document_id
  type
  site_id
  key
  title
  img
  commission_price
  description
  your_message
  is_status
  parent_ids
  published_unixtime
  ignore_product_ids
  display_order_number
  gift_product_id
  created_unixtime
  updated_unixtime
}
    `;
export const GiftPatternWithProductFragmentFragmentDoc = gql`
    fragment GiftPatternWithProductFragment on GiftPattern {
  document_id
  type
  site_id
  key
  title
  img
  commission_price
  description
  your_message
  is_status
  parent_ids
  published_unixtime
  ignore_product_ids
  display_order_number
  gift_product_id
  created_unixtime
  updated_unixtime
  product {
    document_id
    name
    slug
    pass_query
    material
    keywords
    is_status
    explanation
    release_date
    writer_user_id
    product_category_ids
    recommend_product_ids
    related_products_ids
    ranking_sale_order
    site_id
    not_display_product_list
    is_gift
    cart_only_gift
    color_type
    is_all_coupons_not_applicable
    query_published_sites {
      kencoco
      affiliate_only
      shops
    }
    skus {
      document_id
      name
      is_status
      size
      sku_code
      color
      color_code
      color_label
      product_id
      main_image
      main_image_content
      consumption_tax_percentage
      price
      writer_user_id
      size_label
      display_order_number
      stock_quantity
      stock_quantity_limit
      net_stock_quantity
      net_stock_quantity_limit
      egift_stock_quantity_limit
      scm_type
      site_id
      is_reservation
      is_restock_notice
      shipping_supplement
      sales_status
      sub_images {
        content
        image_url
        is_first_view
        is_color_image
        display_order_number
        is_wrapping
      }
      size_supplement
      maximum_wrapping_size
      minimum_wrapping_size
      compare_price
      package_size
      is_optional_shipping_supplement
      is_set_sku
      jan_code
      management_jan_code
      is_offline
    }
    product_categories {
      document_id
      name
      slug
      parent_category_id
      parent_category {
        name
        slug
      }
    }
  }
}
    `;
export const HelpCategoryFragmentDoc = gql`
    fragment HelpCategory on HelpCategory {
  document_id
  is_status
  name
  site_id
  display_order_number
  description
  icon_img_url
  created_unixtime
  updated_unixtime
}
    `;
export const HelpCategoryWithHelpsFragmentDoc = gql`
    fragment HelpCategoryWithHelps on HelpCategory {
  document_id
  is_status
  name
  site_id
  display_order_number
  description
  icon_img_url
  created_unixtime
  updated_unixtime
  helps {
    title
    content
    display_order_number
    document_id
  }
}
    `;
export const JournalFragmentDoc = gql`
    fragment Journal on Journal {
  document_id
  site_id
  title
  description
  lead
  slug
  thumbnail_image
  ogp
  pass_query
  content
  display_slider_number
  display_order_number
  release_date
  journal_common_part_id
  writer_user_id
  supervisor_id
  product_category_id
  product_sub_category_id
  display_top
  featured_content
  is_status
  journal_common_part {
    document_id
    title
    content_code
  }
  product_category {
    document_id
    name
    parent_category_id
    slug
  }
  product_sub_category {
    document_id
    name
    parent_category_id
    slug
  }
  supervisor {
    document_id
    name
    description
    icon_image
  }
  created_unixtime
  updated_unixtime
}
    `;
export const MileHistoryFragmentDoc = gql`
    fragment MileHistory on MileHistory {
  document_id
  user_id
  mile
  type
  event_name
  expired_unixtime
  mile_history_id
  remaining_id
  order_id
  coupon_id
  created_unixtime
  updated_unixtime
  group_id
}
    `;
export const NewsFragmentDoc = gql`
    fragment News on News {
  document_id
  site_id
  slug
  title
  description
  thumbnail_image
  ogp
  lead
  content
  release_date
  writer_user_id
  is_status
  pass_query
  news_group_id
  created_unixtime
  updated_unixtime
}
    `;
export const NewsGroupFragmentDoc = gql`
    fragment NewsGroup on NewsGroup {
  document_id
  is_status
  title
  limit
  is_archived
  active_news {
    document_id
    site_id
    slug
    title
    description
    thumbnail_image
    ogp
    lead
    content
    release_date
    is_status
    pass_query
    writer_user_id
    news_group_id
    created_unixtime
    updated_unixtime
  }
  created_unixtime
  updated_unixtime
}
    `;
export const OrderFragmentDoc = gql`
    fragment Order on Order {
  document_id
  email
  user_id
  payment_type
  payment_status
  delivered_status
  purchased_site
  amount
  amount_total
  amount_captured
  charge_id
  is_logiless_registration
  stripe_payment_intent_id
  amazon_pay_session_id
  wms_integration_status
  total_details {
    amount_discount
    amount_shipping
    amount_tax
    amount_discount_exclude_tax
  }
  items {
    color
    consumption_tax_percentage
    droped_unixtime
    is_bundled_wrapping
    is_reservation
    original_gift_ids
    price
    price_exclude_tax
    product_id
    product_name
    size
    sku_id
    sku_name
    gift {
      img
      message
      message_img
      message_price
      message_sku_code
      card
      card_img
      card_price
      card_sku_code
      price
      product_name
      size
      sku_code
      sku_id
      sku_name
    }
  }
  user_info {
    tel
    name
    zip_code
    prefecture
    address_line1
    address_line2
  }
  address_info {
    tel
    name
    zip_code
    prefecture
    address_line1
    address_line2
  }
  shipping_info {
    shipping_referred_type
    delivery_company
    is_batch
    shipped_date
    shipped_number
    shipping_referred_date
    shipping_referred_time
    delivery_placement
  }
  refferal_query {
    ad_url
    to_url
  }
  traffic_query {
    full_path
  }
  created_unixtime
  updated_unixtime
}
    `;
export const OrderDetailFragmentDoc = gql`
    fragment OrderDetail on OrderDetail {
  document_id
  email
  user_id
  payment_type
  payment_status
  delivered_status
  purchased_site
  amount
  amount_total
  amount_sub_total
  is_guest
  type
  amount_captured
  original_gifts {
    original_id
    gift_pattern_id
  }
  charge_id
  is_logiless_registration
  stripe_payment_intent_id
  amazon_pay_session_id
  total_package_size
  wms_integration_status
  details_by_item {
    sku_id
    subscription_item_id
    quantity
    price
    discount_price
    reference_price
    reference_discount_price
  }
  total_details {
    amount_discount
    amount_shipping
    amount_tax
    gift_fee
    payment_type_fee
    amount_discount_exclude_tax
    embroidery_fee
  }
  items {
    color
    consumption_tax_percentage
    droped_unixtime
    is_bundled_wrapping
    is_wrapping_product
    wrapping_sku_id
    is_reservation
    original_gift_ids
    price
    price_exclude_tax
    product_id
    product_name
    size
    sku_id
    sku_name
    gift {
      img
      message
      message_img
      message_price
      message_sku_code
      card
      card_img
      card_price
      card_sku_code
      price
      product_name
      size
      sku_code
      sku_id
      sku_name
    }
    embroidery {
      text
      price
      color
      font
      position
    }
  }
  user_info {
    tel
    name
    zip_code
    prefecture
    address_line1
    address_line2
  }
  address_info {
    tel
    name
    zip_code
    prefecture
    address_line1
    address_line2
  }
  refferal_query {
    ad_url
    to_url
  }
  traffic_query {
    full_path
  }
  shipping_info {
    shipping_referred_type
    delivery_company
    is_batch
    shipped_date
    shipped_number
    shipping_referred_date
    shipping_referred_time
    delivery_placement
  }
  created_unixtime
  updated_unixtime
  skus {
    document_id
    name
    size
    sku_code
    compare_price
    product_id
    consumption_tax_percentage
    price
    package_size
    is_reservation
    is_set_sku
    is_status
    display_order_number
    stock_quantity
    stock_quantity_limit
    net_stock_quantity
    net_stock_quantity_limit
    egift_stock_quantity_limit
    scm_type
    is_restock_notice
    is_optional_shipping_supplement
    main_image
    maximum_wrapping_size
    minimum_wrapping_size
    sales_status
    color
    sub_images {
      content
      image_url
      is_first_view
      is_color_image
      display_order_number
      is_wrapping
    }
    jan_code
    management_jan_code
    is_offline
  }
  products {
    document_id
    name
    slug
    pass_query
    material
    keywords
    is_status
    is_wrapping
    is_not_individual_order
    order_quantity_limit
    order_quantity_limit_type
    explanation
    release_date
    writer_user_id
    product_category_ids
    recommend_product_ids
    related_products_ids
    ranking_sale_order
    site_id
    not_display_product_list
    is_gift
    cart_only_gift
    is_return_or_exchange
    color_type
    display_priority
    is_embroidery
    embroidery {
      embroidery_positions
      embroidery_position_img
    }
    size_chart {
      pc_img_url
      sp_img_url
    }
    query_published_sites {
      kencoco
      affiliate_only
      shops
    }
    skus {
      document_id
      name
      is_status
      size
      sku_code
      color
      color_code
      color_label
      product_id
      main_image
      main_image_content
      consumption_tax_percentage
      price
      compare_price
      package_size
      is_set_sku
      is_optional_shipping_supplement
      writer_user_id
      size_label
      display_order_number
      stock_quantity
      stock_quantity_limit
      net_stock_quantity
      net_stock_quantity_limit
      egift_stock_quantity_limit
      scm_type
      site_id
      is_reservation
      is_restock_notice
      shipping_supplement
      sales_status
      sub_images {
        content
        image_url
        is_first_view
        is_color_image
        display_order_number
        is_wrapping
      }
      size_supplement
      maximum_wrapping_size
      minimum_wrapping_size
      jan_code
      management_jan_code
      is_offline
    }
    product_categories {
      document_id
      name
      slug
      parent_category_id
      not_display_search
      parent_category {
        name
        slug
      }
    }
    size_range
    promotion_label_ids
    promotion_labels {
      label_name
      document_id
      display_order_number
      background_color
      label_color
    }
    functionality_label_ids
    functionality_labels {
      label_name
      document_id
      display_order_number
    }
    sex
    fabric
    season
    wear_category
    is_general_medical_device
    is_quasi_drug
    is_all_coupons_not_applicable
    is_not_display_stock
  }
  product_refer_sku {
    document_id
    name
    slug
    pass_query
    material
    keywords
    is_status
    is_wrapping
    is_not_individual_order
    order_quantity_limit
    order_quantity_limit_type
    explanation
    release_date
    writer_user_id
    product_category_ids
    recommend_product_ids
    related_products_ids
    ranking_sale_order
    site_id
    not_display_product_list
    is_gift
    cart_only_gift
    is_return_or_exchange
    color_type
    display_priority
    is_embroidery
    embroidery {
      embroidery_positions
      embroidery_position_img
    }
    size_chart {
      pc_img_url
      sp_img_url
    }
    query_published_sites {
      kencoco
      affiliate_only
      shops
    }
    skus {
      document_id
      name
      is_status
      size
      sku_code
      color
      color_code
      color_label
      product_id
      main_image
      main_image_content
      consumption_tax_percentage
      price
      compare_price
      writer_user_id
      size_label
      display_order_number
      stock_quantity
      stock_quantity_limit
      net_stock_quantity
      net_stock_quantity_limit
      egift_stock_quantity_limit
      scm_type
      site_id
      is_reservation
      is_restock_notice
      shipping_supplement
      sales_status
      package_size
      is_set_sku
      is_optional_shipping_supplement
      sub_images {
        content
        image_url
        is_first_view
        is_color_image
        display_order_number
        is_wrapping
      }
      size_supplement
      maximum_wrapping_size
      minimum_wrapping_size
      jan_code
      management_jan_code
      is_offline
    }
    product_categories {
      document_id
      name
      slug
      parent_category_id
      not_display_search
      parent_category {
        name
        slug
      }
    }
    size_range
    promotion_label_ids
    promotion_labels {
      label_name
      document_id
      display_order_number
      background_color
      label_color
    }
    functionality_label_ids
    functionality_labels {
      label_name
      document_id
      display_order_number
    }
    sex
    fabric
    season
    wear_category
    is_general_medical_device
    is_quasi_drug
    is_all_coupons_not_applicable
    is_not_display_stock
  }
  coupon_histories {
    coupon {
      document_id
      site_id
      name
      code
      coupon_text
      min_price
      max_count
      min_quantity
      discount_method
      discount_rate
      discount_fixed_amount
      started_unixtime
      expired_unixtime
      expiration_days
      is_status
      archived_unixtime
      is_archived
      is_free_postage
      coupon_type
      coupon_event
      created_unixtime
      updated_unixtime
      is_auto_coupon
      can_combined_coupon
      is_all_products
      patterns_ignore_sku_ids
      ignore_product_ids
      priority
      is_exchange_coupon
    }
    coupon_at_purchased {
      name
      text
      method
      is_auto
    }
    coupon_id
    created_unixtime
    document_id
    email
    order_id
    site_id
    updated_unixtime
    user_id
    canceled_unixtime
  }
  original_gifts {
    commission_price
    gift_pattern_id
    img
    is_combined
    msg_txt
    original_id
    title
    type
  }
  e_gift {
    document_id
    order_id
    message
    giver_name
    created_unixtime
    updated_unixtime
    gift_type
    status
    token
    email
    stock_products {
      product_id
      sku_ids
    }
    expired_unixtime
    last_expired_unixtime
  }
  logiless_sales_order {
    document_id
    order_id
    sales_order_code
    sales_order_id
    created_unixtime
    updated_unixtime
    logiless_outbound_deliveries {
      document_id
      outbound_delivery_id
      outbound_delivery_code
      logiless_sales_order_id
      delivery_status
      delivery_company
      delivery_tracking_numbers
      scheduled_shipping_date
      shipped_date
      sku_ids
      created_unixtime
      updated_unixtime
    }
  }
}
    `;
export const OrderDetailForThankyouFragmentDoc = gql`
    fragment OrderDetailForThankyou on OrderDetail {
  document_id
  email
  user_id
  payment_type
  payment_status
  delivered_status
  purchased_site
  amount
  amount_total
  amount_sub_total
  is_guest
  type
  amount_captured
  e_gift {
    document_id
    order_id
    token
    expired_unixtime
  }
  products {
    document_id
    slug
    sex
    name
    material
    product_category_ids
    product_categories {
      document_id
      name
      slug
      parent_category_id
      not_display_search
      parent_category {
        name
        slug
      }
    }
  }
  skus {
    document_id
    name
    size
    sku_code
    color
    product_id
    main_image
    price
  }
  mile_histories {
    document_id
    order_id
    mile
  }
  created_unixtime
  user_info {
    name
  }
  items {
    price_exclude_tax
    price
    sku_id
    sku_name
    product_id
    product_name
    gift {
      sku_id
    }
    is_bundled_wrapping
    is_wrapping_product
  }
  total_details {
    amount_discount_exclude_tax
  }
}
    `;
export const PageFragmentFragmentDoc = gql`
    fragment PageFragment on Page {
  document_id
  title
  description
  ogp_url
  absolute_url
  show_other_interviews
  target_product_id
  redirect_url
  redirect_on
  popular_articles
  feature_pickup
  is_not_display_in_list
  lead_text
  is_overseas
}
    `;
export const TopPageFragmentFragmentDoc = gql`
    fragment TopPageFragment on Page {
  document_id
  title
  description
  ogp_url
  redirect_url
  redirect_on
}
    `;
export const PageFindFragmentFragmentDoc = gql`
    fragment PageFindFragment on Page {
  document_id
  title
  description
  ogp_url
  thumbnail_pc_url
  thumbnail_sp_url
  absolute_url
  redirect_url
  redirect_on
  popular_articles
  feature_pickup
  campaign_pickup
  display_order_number
  is_not_display_in_list
  lead_text
  is_overseas
  updated_unixtime
}
    `;
export const PagePartsFragmentFragmentDoc = gql`
    fragment PagePartsFragment on PagePartsItem {
  document_id
  updated_unixtime
  product_id
  product {
    document_id
    name
    slug
    pass_query
    material
    is_status
    is_wrapping
    wrapping_sku_id
    is_not_individual_order
    order_quantity_limit
    order_quantity_limit_type
    explanation
    release_date
    writer_user_id
    product_category_ids
    recommend_product_ids
    related_products_ids
    size_range
    promotion_label_ids
    promotion_labels {
      label_name
      document_id
      display_order_number
      background_color
      label_color
    }
    functionality_label_ids
    functionality_labels {
      label_name
      document_id
      display_order_number
    }
    sex
    fabric
    season
    wear_category
    is_general_medical_device
    is_quasi_drug
    ranking_sale_order
    site_id
    is_gift
    cart_only_gift
    is_embroidery
    embroidery {
      embroidery_position_img
      embroidery_positions
    }
    color_type
    product_categories {
      document_id
      name
      slug
      parent_category_id
      not_display_search
      parent_category {
        name
        slug
      }
    }
    query_published_sites {
      kencoco
      affiliate_only
    }
    size_chart {
      pc_img_url
      sp_img_url
    }
    created_unixtime
    updated_unixtime
    display_priority
    keywords
    is_return_or_exchange
    not_display_product_list
    is_all_coupons_not_applicable
    is_not_display_stock
    top_image_alt
    skus {
      document_id
      name
      compare_price
      is_status
      size
      sku_code
      color
      color_code
      color_label
      product_id
      main_image
      main_image_content
      consumption_tax_percentage
      price
      writer_user_id
      size_label
      display_order_number
      stock_quantity
      stock_quantity_limit
      net_stock_quantity
      net_stock_quantity_limit
      egift_stock_quantity_limit
      scm_type
      site_id
      is_reservation
      shipping_supplement
      is_restock_notice
      is_set_sku
      maximum_wrapping_size
      minimum_wrapping_size
      is_optional_shipping_supplement
      sales_status
      sub_images {
        content
        image_url
        is_first_view
        is_color_image
        display_order_number
        is_wrapping
      }
      size_supplement
      package_size
      jan_code
      management_jan_code
      is_offline
      offline_related_jan_code
    }
    expected_release_unixtime
  }
  product_ids
  product_id_and_urls {
    product_id
    url
  }
  product_id_and_categories {
    product_id
    category_title
    category_short
  }
  products {
    document_id
    name
    slug
    pass_query
    material
    is_status
    explanation
    release_date
    writer_user_id
    product_category_ids
    recommend_product_ids
    related_products_ids
    size_range
    promotion_label_ids
    promotion_labels {
      label_name
      document_id
      display_order_number
      background_color
      label_color
    }
    functionality_label_ids
    functionality_labels {
      label_name
      document_id
      display_order_number
    }
    sex
    fabric
    season
    wear_category
    is_general_medical_device
    is_quasi_drug
    ranking_sale_order
    site_id
    is_gift
    cart_only_gift
    is_embroidery
    embroidery {
      embroidery_position_img
      embroidery_positions
    }
    color_type
    product_categories {
      document_id
      name
      slug
      parent_category_id
      not_display_search
      parent_category {
        name
        slug
      }
    }
    query_published_sites {
      kencoco
      affiliate_only
    }
    size_chart {
      pc_img_url
      sp_img_url
    }
    created_unixtime
    updated_unixtime
    display_priority
    keywords
    is_return_or_exchange
    not_display_product_list
    is_all_coupons_not_applicable
    is_not_display_stock
    top_image_alt
    skus {
      document_id
      name
      compare_price
      is_status
      size
      sku_code
      color
      color_code
      color_label
      product_id
      main_image
      main_image_content
      consumption_tax_percentage
      price
      writer_user_id
      size_label
      display_order_number
      maximum_wrapping_size
      minimum_wrapping_size
      is_optional_shipping_supplement
      sales_status
      stock_quantity
      stock_quantity_limit
      net_stock_quantity
      net_stock_quantity_limit
      egift_stock_quantity_limit
      scm_type
      site_id
      is_reservation
      shipping_supplement
      is_restock_notice
      is_set_sku
      sub_images {
        content
        image_url
        is_first_view
        is_color_image
        display_order_number
        is_wrapping
      }
      size_supplement
      package_size
      jan_code
      management_jan_code
      is_offline
      offline_related_jan_code
    }
    expected_release_unixtime
  }
  category_id
  category {
    document_id
    slug
    name
    parent_category_id
    child_categories {
      name
      slug
    }
  }
  detail {
    url
    common_img_url
    sp_common_img_url
    img_url
    sp_img_url
    video_url
    sp_video_url
    video_auto_play
    sp_video_auto_play
    video_loop_playback
    sp_video_loop_playback
    video_controls
    sp_video_controls
    title
    sp_title
    description
    sp_description
    desc_font_size
    sp_desc_font_size
    text_align
    sp_text_align
    bg_color
    subtitle
    sp_subtitle
    text_color
    modal_bg_color
    img_url_caption
    sp_img_url_caption
    font_size
    sp_font_size
    sub_font_size
    sp_sub_font_size
    font_weight
    sub_font_weight
    orientation
    pc_margin
    sp_margin
    bg_img_url
    bg_sp_img_url
    youtube_id
    is_movie
    sub_text1
    sp_sub_text1
    sub_text2
    sp_sub_text2
    sub_text3
    sp_sub_text3
    sub_text_font_size
    sp_sub_text_font_size
    sub_text_font_weight
    sub_text_color
    sub_bg_color
    pc_img_column
    sp_img_column
    is_img_navigation
    is_img_pagination
    is_hide_ranking_num
  }
  layout_type
  list {
    body
    description
    url
    link_text
    icon
    product_id
    pc_img_url
    sp_img_url
    pc_img_url_caption
    sp_img_url_caption
    title
    subtitle
    sub_text1
    sub_text2
    display_order_number
  }
  display_type
  display_order_number
  is_status
  review_group_id
  review_group {
    site_id
    document_id
    title
    limit
    status
    is_archived
    reviews {
      site_id
      document_id
      age
      body
      display_order
      name
      product_id
      sex
      star
      status
      title
      height
      size
      is_archived
      review_group_id
    }
  }
  news_group_id
  interview_page_ids
  child_page_parts_ids
}
    `;
export const ProductFragmentFragmentDoc = gql`
    fragment ProductFragment on Product {
  document_id
  updated_unixtime
  name
  slug
  pass_query
  material
  keywords
  is_status
  is_wrapping
  is_not_individual_order
  order_quantity_limit
  order_quantity_limit_type
  explanation
  release_date
  writer_user_id
  product_category_ids
  recommend_product_ids
  related_products_ids
  functionality_label_ids
  functionality_labels {
    label_name
    document_id
    display_order_number
  }
  is_general_medical_device
  is_quasi_drug
  promotion_labels {
    label_name
    document_id
    display_order_number
    background_color
    label_color
  }
  ranking_sale_order
  display_priority
  site_id
  not_display_product_list
  is_gift
  cart_only_gift
  is_embroidery
  embroidery {
    embroidery_positions
    embroidery_position_img
  }
  is_return_or_exchange
  color_type
  size_chart {
    pc_img_url
    sp_img_url
  }
  query_published_sites {
    kencoco
    affiliate_only
    shops
  }
  skus {
    document_id
    product_id
    name
    is_status
    size
    sku_code
    color
    color_code
    color_label
    chip_image_url
    product_id
    main_image
    main_image_content
    consumption_tax_percentage
    package_size
    is_set_sku
    is_optional_shipping_supplement
    price
    writer_user_id
    size_label
    display_order_number
    stock_quantity
    stock_quantity_limit
    net_stock_quantity
    net_stock_quantity_limit
    egift_stock_quantity_limit
    scm_type
    compare_price
    site_id
    is_reservation
    is_restock_notice
    shipping_supplement
    sales_status
    sub_images {
      content
      image_url
      is_first_view
      is_color_image
      display_order_number
      is_wrapping
    }
    size_supplement
    maximum_wrapping_size
    minimum_wrapping_size
    jan_code
    management_jan_code
    is_offline
    offline_related_jan_code
    expected_release_unixtime
  }
  product_categories {
    document_id
    name
    slug
    parent_category_id
    not_display_search
    parent_category {
      name
      slug
    }
  }
  size_range
  promotion_label_ids
  promotion_labels {
    label_name
    document_id
    display_order_number
    background_color
    label_color
  }
  functionality_label_ids
  functionality_labels {
    label_name
    document_id
    display_order_number
  }
  sex
  fabric
  season
  wear_category
  is_all_coupons_not_applicable
  is_not_display_stock
  expected_release_unixtime
  wrapping_sku_id
  top_image_alt
}
    `;
export const RecommendProductFragmentFragmentDoc = gql`
    fragment RecommendProductFragment on Product {
  document_id
  name
  slug
  material
  is_status
  is_status
  product_category_ids
  color_type
  skus(limit: 2) {
    document_id
    product_id
    main_image
    consumption_tax_percentage
    price
    site_id
  }
  product_categories(limit: 1) {
    document_id
    slug
    parent_category_id
    not_display_search
    parent_category {
      name
      slug
    }
  }
  expected_release_unixtime
}
    `;
export const ProductCategoryFragmentDoc = gql`
    fragment ProductCategory on ProductCategory {
  document_id
  name
  slug
  meta_title
  icon_image
  description
  parent_category_id
  sidebar_order_number
  display_order_number
  media_type {
    goods
    columns
  }
  public_destination {
    kencoco
    media
  }
  is_status
  created_unixtime
  updated_unixtime
  parent_category {
    document_id
    name
    slug
  }
}
    `;
export const ChildCategoriesFragmentDoc = gql`
    fragment ChildCategories on ProductCategory {
  document_id
  name
  slug
  parent_category_id
}
    `;
export const ParentCategoryWithChildFragmentDoc = gql`
    fragment ParentCategoryWithChild on ParentCategoryWithChild {
  document_id
  slug
  name
  meta_title
  icon_image
  description
  parent_category_id
  sidebar_order_number
  display_order_number
  media_type {
    goods
    columns
  }
  public_destination {
    kencoco
    media
  }
  child_categories {
    ...ChildCategories
  }
  is_status
  created_unixtime
  updated_unixtime
}
    ${ChildCategoriesFragmentDoc}`;
export const PromotionLabelFragmentDoc = gql`
    fragment PromotionLabel on PromotionLabel {
  document_id
  label_name
  display_order_number
  background_color
  label_color
  is_status
}
    `;
export const QuestionnaireFragmentFragmentDoc = gql`
    fragment QuestionnaireFragment on Questionnaire {
  document_id
  stripe_subscription_id
  order_id
  user_id
  site_id
  type
  answer {
    reason_text
    demand_text
    new_product_request_text
    question_site_understand
    question_site_useful
    question_site_find_product
    question_site_payment_process
  }
  user {
    gender
    birthday_year
    birthday_month
    birthday_date
    worries
    referral_sources
    referral_source_search_text
    commercial_perception
    occupation
    salary
    hobby
    body_weight
    body_height
    foot_size
    exercise_frequency
    offline_traffic_source
  }
  created_unixtime
  updated_unixtime
}
    `;
export const RestockReceptionFragmentDoc = gql`
    fragment RestockReception on RestockReception {
  document_id
  created_unixtime
  updated_unixtime
  email
  product_id
  sku_id
  sku_code
  size
  name
  color
  site_id
  send_date
  page_slug
  local_user_id
  status
}
    `;
export const ReturnOrExchangeRequestFragmentDoc = gql`
    fragment ReturnOrExchangeRequest on ReturnOrExchangeRequest {
  document_id
  receipt_id
  user_id
  site_member_id
  request_type
  order_id
  approval_status
  approval_unixtime
  return_status
  return_address
  return_unixtime
  inspection_status
  inspection_details
  refund_status
  admins_comment
  warehouse_comment
  exchange_logiless_sales_order {
    sales_order_id
    sales_order_code
    sales_order_delivery_status
  }
  exchange_logiless_delivery_lists {
    outbound_delivery_id
    outbound_delivery_code
    delivery_company
    delivery_status
    delivery_tracking_numbers
    scheduled_shipping_date
    shipped_date
  }
  request_quantity
  request_reasons
  request_items_opening_status
  request_items_img_urls
  request_user_comments
  target_sku_id
  request_sku_id
  request_user_info {
    name
    email
  }
  shipping_address {
    address_line1
    address_line2
    name
    prefecture
    tel
    zip_code
  }
  purchase_channel
  foreign_order {
    rakuten_id
    amazon_id
    giftmall_id
    hometown_tax_id
    store_id
    others_id
    outbound_delivery_code
  }
  receipt_img_url
  return_scope
  created_unixtime
  updated_unixtime
}
    `;
export const ReviewFragmentDoc = gql`
    fragment Review on Review {
  site_id
  document_id
  age
  body
  display_order
  name
  product_id
  sex
  star
  status
  title
  height
  weight
  foot_size
  foot
  usability
  size
  is_archived
  review_group_id
}
    `;
export const ReviewGroupFragmentDoc = gql`
    fragment ReviewGroup on ReviewGroup {
  site_id
  document_id
  title
  limit
  status
  is_archived
  reviews {
    site_id
    document_id
    age
    body
    display_order
    name
    product_id
    sex
    star
    status
    title
    height
    weight
    foot_size
    foot
    usability
    size
    is_archived
    review_group_id
  }
}
    `;
export const RouteLogFragmentDoc = gql`
    fragment RouteLog on RouteLog {
  document_id
  url
  stamped_unixtime
  order_id
  user_id
  local_user_id
  created_unixtime
  updated_unixtime
}
    `;
export const SiteFragmentDoc = gql`
    fragment Site on Site {
  document_id
  logiless_store_id
  domain
  sender_email
  slug
  name
  name_kana
  company {
    facebook
    twitter
    instagram
  }
  settings {
    total_commission
    credit_card_available
    credit_card_commission
    amazon_pay_available
    amazon_pay_commission
    cod_available
    cod_commission
    postage_price
  }
}
    `;
export const SkuFragmentFragmentDoc = gql`
    fragment SkuFragment on Sku {
  document_id
  name
  size
  sku_code
  color
  color_code
  color_label
  chip_image_url
  compare_price
  product_id
  main_image
  main_image_content
  consumption_tax_percentage
  price
  site_id
  package_size
  is_reservation
  sub_images {
    content
    image_url
    is_first_view
    is_color_image
    display_order_number
    is_wrapping
  }
  is_set_sku
  is_status
  writer_user_id
  size_label
  display_order_number
  stock_quantity
  stock_quantity_limit
  net_stock_quantity
  net_stock_quantity_limit
  egift_stock_quantity_limit
  scm_type
  size_supplement
  is_restock_notice
  is_optional_shipping_supplement
  shipping_supplement
  maximum_wrapping_size
  minimum_wrapping_size
  sales_status
  jan_code
  management_jan_code
  is_offline
  offline_related_jan_code
  created_unixtime
  updated_unixtime
  expected_release_unixtime
}
    `;
export const PurchasedProductSkuFragmentDoc = gql`
    fragment PurchasedProductSku on PurchasedProductSku {
  document_id
  size
  color
  image_url
  product_id
  product_name
  product_slug
  product_material
  product_status
  is_general_medical_device
  order_id
  is_e_gift
  purchased_unixtime
  store_name
  product_page_url
  can_review
}
    `;
export const SkuWithProductFragmentDoc = gql`
    fragment SkuWithProduct on Sku {
  document_id
  name
  size
  sku_code
  color
  color_code
  color_label
  chip_image_url
  compare_price
  product_id
  product {
    document_id
    name
    slug
    pass_query
    material
    keywords
    is_status
    explanation
    release_date
    writer_user_id
    product_category_ids
    recommend_product_ids
    related_products_ids
    functionality_label_ids
    functionality_labels {
      label_name
      document_id
      display_order_number
    }
    is_general_medical_device
    is_quasi_drug
    promotion_labels {
      label_name
      document_id
      display_order_number
      background_color
      label_color
    }
    ranking_sale_order
    display_priority
    site_id
    not_display_product_list
    is_gift
    is_return_or_exchange
    color_type
    size_chart {
      pc_img_url
      sp_img_url
    }
    query_published_sites {
      kencoco
      affiliate_only
      shops
    }
    skus {
      document_id
      name
      is_status
      size
      sku_code
      color
      color_code
      color_label
      chip_image_url
      product_id
      main_image
      main_image_content
      consumption_tax_percentage
      package_size
      is_set_sku
      is_optional_shipping_supplement
      price
      writer_user_id
      size_label
      display_order_number
      stock_quantity
      stock_quantity_limit
      net_stock_quantity
      net_stock_quantity_limit
      egift_stock_quantity_limit
      scm_type
      compare_price
      site_id
      is_reservation
      is_restock_notice
      shipping_supplement
      sales_status
      sub_images {
        content
        image_url
        is_first_view
        is_color_image
        display_order_number
        is_wrapping
      }
      size_supplement
      maximum_wrapping_size
      minimum_wrapping_size
      jan_code
      management_jan_code
      is_offline
    }
    product_categories {
      document_id
      name
      slug
      parent_category_id
      not_display_search
      parent_category {
        name
        slug
      }
    }
    size_range
    promotion_label_ids
    promotion_labels {
      label_name
      document_id
      display_order_number
      background_color
      label_color
    }
    functionality_label_ids
    functionality_labels {
      label_name
      document_id
      display_order_number
    }
    sex
    fabric
    season
    wear_category
    expected_release_unixtime
  }
  main_image
  main_image_content
  consumption_tax_percentage
  price
  site_id
  package_size
  is_reservation
  sub_images {
    content
    image_url
    is_first_view
    is_color_image
    display_order_number
    is_wrapping
  }
  is_set_sku
  is_status
  writer_user_id
  size_label
  display_order_number
  stock_quantity
  stock_quantity_limit
  net_stock_quantity
  net_stock_quantity_limit
  egift_stock_quantity_limit
  scm_type
  size_supplement
  is_restock_notice
  is_optional_shipping_supplement
  shipping_supplement
  maximum_wrapping_size
  minimum_wrapping_size
  sales_status
  jan_code
  management_jan_code
  is_offline
  expected_release_unixtime
}
    `;
export const StockFragmentDoc = gql`
    fragment Stock on Stock {
  document_id
  sku_id
  store_id
  stock_quantity
  stock_limit
  sku {
    document_id
    name
    size
    color
    price
    is_status
    product_id
    jan_code
    product {
      document_id
      name
      is_status
      wear_category
    }
  }
  store {
    document_id
    store_name
    address
    prefecture
    post_code
    type
    is_status
  }
  normal_stock_text
  few_stock_text
  none_stock_text
}
    `;
export const StoreFragmentDoc = gql`
    fragment Store on Store {
  document_id
  type
  scale
  company
  products
  others_select
  store_name
  image
  prefecture
  post_code
  address
  tell_number
  hours
  detail_url
  map_url
  describe
  label_text
  label_color
  label_bg_color
  display_order_number
  site_id
  is_status
  location {
    type
    coordinates
  }
  created_unixtime
  updated_unixtime
}
    `;
export const SurveyQuestionFragmentFragmentDoc = gql`
    fragment SurveyQuestionFragment on SurveyQuestionFindOne_SurveyQuestion {
  document_id
  title
  description
  thankyou_page_title
  thankyou_page_text
  expired_unixtime
  is_status
  survey_question_parts {
    document_id
    description
    description_suffix
    display_order
    is_status
    display_condition {
      is_status
      display_order
      choice
    }
    type
    content
  }
}
    `;
export const UserForSignFragmentDoc = gql`
    fragment UserForSign on User {
  document_id
  site_id
  email
  device_token
  access_token
  refresh_token
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  document_id
  site_id
  name
  nickname
  name_kana
  email
  tel
  receive_mail
  zip_code
  prefecture
  address_line1
  address_line2
  line_id
  is_status
  last_login_unixtime
  archived_unixtime
  logical_deleted_unixtime
  default_user_address_id
  stripe_customer_id
  external_service_id
  rank
  annual_mile
  total_mile
  available_mile
  gender
  b_day
  worries
  important_people {
    b_day_date
    b_day_month
    relationship
  }
  default_user_address {
    tel
    name
    zip_code
    prefecture
    address_line1
    address_line2
  }
  user_addresses {
    document_id
    tel
    _id
    user_id
    name
    zip_code
    prefecture
    address_line1
    address_line2
  }
  stores {
    document_id
    store_name
    is_status
  }
  site {
    document_id
    rank_set {
      name
      threshold
      rate
    }
  }
}
    `;
export const UserDetailFragmentDoc = gql`
    fragment UserDetail on UserDetail {
  document_id
  site_id
  name
  name_kana
  nickname
  email
  tel
  receive_mail
  zip_code
  prefecture
  address_line1
  address_line2
  line_id
  is_status
  last_login_unixtime
  archived_unixtime
  logical_deleted_unixtime
  default_user_address_id
  stripe_customer_id
  external_service_id
  rank
  annual_mile
  total_mile
  available_mile
  gender
  b_day
  worries
  important_people {
    b_day_date
    b_day_month
    relationship
  }
  mile_histories {
    document_id
    user_id
    mile
    type
  }
  site {
    document_id
    rank_set {
      name
      threshold
      rate
      coupon_id
    }
  }
}
    `;
export const UserAddressFragmentFragmentDoc = gql`
    fragment UserAddressFragment on UserAddress {
  _id
  document_id
  name
  zip_code
  user_id
  prefecture
  address_line1
  address_line2
  tel
  created_unixtime
  updated_unixtime
}
    `;
export const UserCouponTypeFragmentFragmentDoc = gql`
    fragment UserCouponTypeFragment on UserCouponType {
  document_id
  coupon_id
  coupon_event
  target_user_id
  created_user_id
  expired_unixtime
  created_unixtime
  updated_unixtime
  coupon {
    document_id
    name
    public_name
    can_combined_coupon
    code
    coupon_event
    coupon_text
    coupon_type
    min_price
    max_count
    min_quantity
    discount_method
    discount_rate
    discount_fixed_amount
    expiration_days
    expired_unixtime
    is_status
    archived_unixtime
    is_all_products
    is_archived
    is_auto_coupon
    is_exchange_coupon
    is_free_postage
    is_status
    max_count
    min_price
    min_quantity
    patterns {
      category_ids
      product_ids
      sku_ids
    }
    patterns_ignore_sku_ids
    ignore_product_ids
    priority
    started_unixtime
    created_unixtime
    updated_unixtime
    use_loc
    mile_expense
  }
  target_product_names
}
    `;
export const WithdrawalSheetFragmentDoc = gql`
    fragment WithdrawalSheet on WithdrawalSheet {
  document_id
  user_id
  site_id
  reason_type
  reason_text
  created_unixtime
  updated_unixtime
}
    `;
export const AdTrackingCreateDocument = gql`
    mutation adTrackingCreate($record: AdTrackingCreateInput) {
  adTrackingCreate(record: $record) {
    ...AdTracking
  }
}
    ${AdTrackingFragmentDoc}`;

export function useAdTrackingCreateMutation() {
  return Urql.useMutation<Types.AdTrackingCreateMutation, Types.AdTrackingCreateMutationVariables>(Types.AdTrackingCreateDocument);
};
export const PublicAdFindOneDocument = gql`
    query publicAdFindOne($filter: FilterFindOneAdvertiserInput) {
  publicAdFindOne(filter: $filter) {
    ...Advertiser
  }
}
    ${AdvertiserFragmentDoc}`;

export function usePublicAdFindOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicAdFindOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicAdFindOneQuery>({ query: Types.PublicAdFindOneDocument, ...options });
};
export const VerifyUserTokenDocument = gql`
    mutation verifyUserToken {
  verifyUserToken
}
    `;

export function useVerifyUserTokenMutation() {
  return Urql.useMutation<Types.VerifyUserTokenMutation, Types.VerifyUserTokenMutationVariables>(Types.VerifyUserTokenDocument);
};
export const PublicContactCreateDocument = gql`
    mutation publicContactCreate($record: ContactCreateInput!, $is_product_guide: Boolean) {
  publicContactCreate(record: $record, is_product_guide: $is_product_guide) {
    ...Contact
  }
}
    ${ContactFragmentDoc}`;

export function usePublicContactCreateMutation() {
  return Urql.useMutation<Types.PublicContactCreateMutation, Types.PublicContactCreateMutationVariables>(Types.PublicContactCreateDocument);
};
export const PublicCouponFindOneDocument = gql`
    query publicCouponFindOne($filter: FilterFindOneCouponInput) {
  publicCouponFindOne(filter: $filter) {
    ...CouponFragment
  }
}
    ${CouponFragmentFragmentDoc}`;

export function usePublicCouponFindOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicCouponFindOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicCouponFindOneQuery>({ query: Types.PublicCouponFindOneDocument, ...options });
};
export const PublicCouponFindOneWithTargetProductNameDocument = gql`
    query publicCouponFindOneWithTargetProductName($filter: FilterFindOneCouponInput) {
  publicCouponFindOneWithTargetProductName(filter: $filter) {
    ...CouponWithTargetProductName
  }
}
    ${CouponWithTargetProductNameFragmentDoc}`;

export function usePublicCouponFindOneWithTargetProductNameQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicCouponFindOneWithTargetProductNameQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicCouponFindOneWithTargetProductNameQuery>({ query: Types.PublicCouponFindOneWithTargetProductNameDocument, ...options });
};
export const OwnUserCouponsDocument = gql`
    query ownUserCoupons($getOfflineExcept: Boolean!) {
  ownUserCoupons(getOfflineExcept: $getOfflineExcept) {
    ...CouponWithUserCouponId
  }
}
    ${CouponWithUserCouponIdFragmentDoc}`;

export function useOwnUserCouponsQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnUserCouponsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnUserCouponsQuery>({ query: Types.OwnUserCouponsDocument, ...options });
};
export const OwnCouponHistoryCreateDocument = gql`
    mutation OwnCouponHistoryCreate($record: CouponHistoryCreateInput!) {
  ownCouponHistoryCreate(record: $record) {
    ...CouponHistory
  }
}
    ${CouponHistoryFragmentDoc}`;

export function useOwnCouponHistoryCreateMutation() {
  return Urql.useMutation<Types.OwnCouponHistoryCreateMutation, Types.OwnCouponHistoryCreateMutationVariables>(Types.OwnCouponHistoryCreateDocument);
};
export const OwnCouponHistoryCancelDocument = gql`
    mutation OwnCouponHistoryCancel($document_id: String!) {
  ownCouponHistoryCancel(document_id: $document_id) {
    ...CouponHistory
  }
}
    ${CouponHistoryFragmentDoc}`;

export function useOwnCouponHistoryCancelMutation() {
  return Urql.useMutation<Types.OwnCouponHistoryCancelMutation, Types.OwnCouponHistoryCancelMutationVariables>(Types.OwnCouponHistoryCancelDocument);
};
export const PublicEGiftFindOneDocument = gql`
    query publicEGiftFindOne($filter: FilterFindOneEGiftInput, $skip: Int, $sort: SortFindOneEGiftInput) {
  publicEGiftFindOne(filter: $filter, skip: $skip, sort: $sort) {
    ...EGift
  }
}
    ${EGiftFragmentDoc}`;

export function usePublicEGiftFindOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicEGiftFindOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicEGiftFindOneQuery>({ query: Types.PublicEGiftFindOneDocument, ...options });
};
export const PublicEGiftFindOneCheckExpiryDocument = gql`
    query publicEGiftFindOneCheckExpiry($documentId: String!, $token: String, $siteId: String) {
  publicEGiftFindOneCheckExpiry(
    documentId: $documentId
    token: $token
    siteId: $siteId
  ) {
    ...EGiftWithOrder
  }
}
    ${EGiftWithOrderFragmentDoc}`;

export function usePublicEGiftFindOneCheckExpiryQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicEGiftFindOneCheckExpiryQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicEGiftFindOneCheckExpiryQuery>({ query: Types.PublicEGiftFindOneCheckExpiryDocument, ...options });
};
export const OwnFavoriteCreateDocument = gql`
    mutation OwnFavoriteCreate($skuId: String!, $productId: String!) {
  ownFavoriteCreate(skuId: $skuId, productId: $productId) {
    ...Favorite
  }
}
    ${FavoriteFragmentDoc}`;

export function useOwnFavoriteCreateMutation() {
  return Urql.useMutation<Types.OwnFavoriteCreateMutation, Types.OwnFavoriteCreateMutationVariables>(Types.OwnFavoriteCreateDocument);
};
export const OwnFavoriteRemoveDocument = gql`
    mutation OwnFavoriteRemove($skuId: String!) {
  ownFavoriteRemove(skuId: $skuId) {
    ...Favorite
  }
}
    ${FavoriteFragmentDoc}`;

export function useOwnFavoriteRemoveMutation() {
  return Urql.useMutation<Types.OwnFavoriteRemoveMutation, Types.OwnFavoriteRemoveMutationVariables>(Types.OwnFavoriteRemoveDocument);
};
export const OwnFavoriteFindManyByProductIdDocument = gql`
    query OwnFavoriteFindManyByProductId($productId: String!) {
  ownFavoriteFindManyByProductId(productId: $productId) {
    ...Favorite
  }
}
    ${FavoriteFragmentDoc}`;

export function useOwnFavoriteFindManyByProductIdQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnFavoriteFindManyByProductIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnFavoriteFindManyByProductIdQuery>({ query: Types.OwnFavoriteFindManyByProductIdDocument, ...options });
};
export const OwnFavoritePaginationDocument = gql`
    query OwnFavoritePagination($page: Int!, $limit: Int!) {
  ownFavoritePagination(page: $page, limit: $limit) {
    items {
      ...FavoriteDetail
    }
    count
    pageInfo {
      hasNextPage
      pageCount
    }
  }
}
    ${FavoriteDetailFragmentDoc}`;

export function useOwnFavoritePaginationQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnFavoritePaginationQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnFavoritePaginationQuery>({ query: Types.OwnFavoritePaginationDocument, ...options });
};
export const PublicFunctionalityLabelFindManyDocument = gql`
    query publicFunctionalityLabelFindMany($filter: FilterFindManyFunctionalityLabelInput, $skip: Int, $sort: SortFindManyFunctionalityLabelInput) {
  publicFunctionalityLabelFindMany(filter: $filter, skip: $skip, sort: $sort) {
    ...FunctionalityLabel
  }
}
    ${FunctionalityLabelFragmentDoc}`;

export function usePublicFunctionalityLabelFindManyQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicFunctionalityLabelFindManyQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicFunctionalityLabelFindManyQuery>({ query: Types.PublicFunctionalityLabelFindManyDocument, ...options });
};
export const GiftPatternFindManyDocument = gql`
    query giftPatternFindMany($filter: FilterFindManyGiftPatternInput, $skip: Int, $limit: Int, $sort: SortFindManyGiftPatternInput) {
  giftPatternFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
    ...GiftPatternFragment
  }
}
    ${GiftPatternFragmentFragmentDoc}`;

export function useGiftPatternFindManyQuery(options: Omit<Urql.UseQueryArgs<never, Types.GiftPatternFindManyQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.GiftPatternFindManyQuery>({ query: Types.GiftPatternFindManyDocument, ...options });
};
export const PublicGiftPatternAllDocument = gql`
    query publicGiftPatternAll($filter: FilterFindManyGiftPatternInput) {
  publicGiftPatternAll(filter: $filter) {
    ...GiftPatternWithProductFragment
  }
}
    ${GiftPatternWithProductFragmentFragmentDoc}`;

export function usePublicGiftPatternAllQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicGiftPatternAllQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicGiftPatternAllQuery>({ query: Types.PublicGiftPatternAllDocument, ...options });
};
export const HelpCategoryOneDocument = gql`
    query HelpCategoryOne($filter: FilterFindOneHelpCategoryInput, $skip: Int, $sort: SortFindOneHelpCategoryInput) {
  helpCategoryOne(filter: $filter, skip: $skip, sort: $sort) {
    ...HelpCategory
  }
}
    ${HelpCategoryFragmentDoc}`;

export function useHelpCategoryOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.HelpCategoryOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.HelpCategoryOneQuery>({ query: Types.HelpCategoryOneDocument, ...options });
};
export const HelpCategoryAllDocument = gql`
    query HelpCategoryAll($page: Int, $perPage: Int, $filter: FilterFindManyHelpCategoryInput, $sort: SortFindManyHelpCategoryInput) {
  helpCategoryPagination(
    page: $page
    perPage: $perPage
    filter: $filter
    sort: $sort
  ) {
    count
    items {
      ...HelpCategory
    }
    pageInfo {
      currentPage
      perPage
      pageCount
      itemCount
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${HelpCategoryFragmentDoc}`;

export function useHelpCategoryAllQuery(options: Omit<Urql.UseQueryArgs<never, Types.HelpCategoryAllQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.HelpCategoryAllQuery>({ query: Types.HelpCategoryAllDocument, ...options });
};
export const HelpCategoryAllWithHelpsDocument = gql`
    query HelpCategoryAllWithHelps($page: Int, $perPage: Int, $filter: FilterFindManyHelpCategoryInput, $sort: SortFindManyHelpCategoryInput) {
  helpCategoryPagination(
    page: $page
    perPage: $perPage
    filter: $filter
    sort: $sort
  ) {
    count
    items {
      ...HelpCategoryWithHelps
    }
    pageInfo {
      currentPage
      perPage
      pageCount
      itemCount
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${HelpCategoryWithHelpsFragmentDoc}`;

export function useHelpCategoryAllWithHelpsQuery(options: Omit<Urql.UseQueryArgs<never, Types.HelpCategoryAllWithHelpsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.HelpCategoryAllWithHelpsQuery>({ query: Types.HelpCategoryAllWithHelpsDocument, ...options });
};
export const PublicJournalAllDocument = gql`
    query publicJournalAll($page: Int!, $limit: Int!, $searchText: String, $filter: JournalFindFilter, $site_id: String!) {
  publicJournalAll(
    page: $page
    limit: $limit
    searchText: $searchText
    filter: $filter
    site_id: $site_id
  ) {
    items {
      ...Journal
    }
    count
    pageInfo {
      hasNextPage
    }
  }
}
    ${JournalFragmentDoc}`;

export function usePublicJournalAllQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicJournalAllQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicJournalAllQuery>({ query: Types.PublicJournalAllDocument, ...options });
};
export const PublicJournalOneDocument = gql`
    query publicJournalOne($filter: FilterFindOneJournalInput) {
  publicJournalOne(filter: $filter) {
    ...Journal
  }
}
    ${JournalFragmentDoc}`;

export function usePublicJournalOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicJournalOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicJournalOneQuery>({ query: Types.PublicJournalOneDocument, ...options });
};
export const PublicLogilessOutboundDeliveryCalcDeliveryLtOrderDocument = gql`
    query PublicLogilessOutboundDeliveryCalcDeliveryLtOrder($input: LogilessOutboundDeliveryCalcDeliveryLtOrder_Input!) {
  publicLogilessOutboundDeliveryCalcDeliveryLtOrder(input: $input) {
    delivery_date
    scheduled_shipping_date
  }
}
    `;

export function usePublicLogilessOutboundDeliveryCalcDeliveryLtOrderQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicLogilessOutboundDeliveryCalcDeliveryLtOrderQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicLogilessOutboundDeliveryCalcDeliveryLtOrderQuery>({ query: Types.PublicLogilessOutboundDeliveryCalcDeliveryLtOrderDocument, ...options });
};
export const PublicLogilessOutboundDeliveryCalcDeliveryLtPrefecturesDocument = gql`
    query PublicLogilessOutboundDeliveryCalcDeliveryLtPrefectures {
  publicLogilessOutboundDeliveryCalcDeliveryLtPrefectures {
    default_prefecture
    prefectures {
      delivery_date
      prefecture
    }
  }
}
    `;

export function usePublicLogilessOutboundDeliveryCalcDeliveryLtPrefecturesQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicLogilessOutboundDeliveryCalcDeliveryLtPrefecturesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicLogilessOutboundDeliveryCalcDeliveryLtPrefecturesQuery>({ query: Types.PublicLogilessOutboundDeliveryCalcDeliveryLtPrefecturesDocument, ...options });
};
export const OwnMileHistoryCreateForOnlinePurchaseDocument = gql`
    mutation ownMileHistoryCreateForOnlinePurchase($orderId: String!) {
  ownMileHistoryCreateForOnlinePurchase(orderId: $orderId)
}
    `;

export function useOwnMileHistoryCreateForOnlinePurchaseMutation() {
  return Urql.useMutation<Types.OwnMileHistoryCreateForOnlinePurchaseMutation, Types.OwnMileHistoryCreateForOnlinePurchaseMutationVariables>(Types.OwnMileHistoryCreateForOnlinePurchaseDocument);
};
export const OwnMileHistoryPaginationDocument = gql`
    query ownMileHistoryPagination($page: Int!, $limit: Int!, $type: String) {
  ownMileHistoryPagination(page: $page, limit: $limit, type: $type) {
    items {
      ...MileHistory
    }
    count
    pageInfo {
      hasNextPage
      pageCount
    }
  }
}
    ${MileHistoryFragmentDoc}`;

export function useOwnMileHistoryPaginationQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnMileHistoryPaginationQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnMileHistoryPaginationQuery>({ query: Types.OwnMileHistoryPaginationDocument, ...options });
};
export const OwnTypeAcquisitionFindManyDocument = gql`
    query ownTypeAcquisitionFindMany {
  ownTypeAcquisitionFindMany {
    ...MileHistory
  }
}
    ${MileHistoryFragmentDoc}`;

export function useOwnTypeAcquisitionFindManyQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnTypeAcquisitionFindManyQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnTypeAcquisitionFindManyQuery>({ query: Types.OwnTypeAcquisitionFindManyDocument, ...options });
};
export const PublicNewsOneDocument = gql`
    query publicNewsOne($filter: FilterFindOneNewsInput, $skip: Int, $sort: SortFindOneNewsInput) {
  publicNewsOne(filter: $filter, skip: $skip, sort: $sort) {
    news {
      ...News
    }
    prevSlug
    nextSlug
  }
}
    ${NewsFragmentDoc}`;

export function usePublicNewsOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicNewsOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicNewsOneQuery>({ query: Types.PublicNewsOneDocument, ...options });
};
export const PublicNewsSearchDocument = gql`
    query publicNewsSearch($page: Int!, $limit: Int!, $filter: NewsFindFilter, $sort: NewsSortOption) {
  publicNewsSearch(page: $page, limit: $limit, filter: $filter, sort: $sort) {
    count
    items {
      ...News
    }
    pageInfo {
      hasNextPage
      pageCount
    }
  }
}
    ${NewsFragmentDoc}`;

export function usePublicNewsSearchQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicNewsSearchQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicNewsSearchQuery>({ query: Types.PublicNewsSearchDocument, ...options });
};
export const PublicNewsGroupFindOneDocument = gql`
    query publicNewsGroupFindOne($filter: FilterFindOneNewsGroupInput, $skip: Int, $sort: SortFindOneNewsGroupInput) {
  publicNewsGroupFindOne(filter: $filter, skip: $skip, sort: $sort) {
    ...NewsGroup
  }
}
    ${NewsGroupFragmentDoc}`;

export function usePublicNewsGroupFindOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicNewsGroupFindOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicNewsGroupFindOneQuery>({ query: Types.PublicNewsGroupFindOneDocument, ...options });
};
export const OwnOrderFindAllDocument = gql`
    query ownOrderFindAll($isMyPage: Boolean!) {
  ownOrderFindAll(isMyPage: $isMyPage) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;

export function useOwnOrderFindAllQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnOrderFindAllQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnOrderFindAllQuery>({ query: Types.OwnOrderFindAllDocument, ...options });
};
export const OwnOrderFindOneDocument = gql`
    query ownOrderFindOne($isMyPage: Boolean!, $documentId: String!) {
  ownOrderFindOne(isMyPage: $isMyPage, documentId: $documentId) {
    ...OrderDetail
  }
}
    ${OrderDetailFragmentDoc}`;

export function useOwnOrderFindOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnOrderFindOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnOrderFindOneQuery>({ query: Types.OwnOrderFindOneDocument, ...options });
};
export const OwnOrderSearchDocument = gql`
    query ownOrderSearch($page: Int!, $limit: Int!, $filter: FilterFindManyOrderInput) {
  ownOrderSearch(page: $page, limit: $limit, filter: $filter) {
    count
    items {
      ...Order
      details_by_item {
        sku_id
        subscription_item_id
        quantity
        price
        discount_price
        reference_price
        reference_discount_price
      }
    }
    pageInfo {
      currentPage
      hasNextPage
      hasPreviousPage
      pageCount
    }
  }
}
    ${OrderFragmentDoc}`;

export function useOwnOrderSearchQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnOrderSearchQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnOrderSearchQuery>({ query: Types.OwnOrderSearchDocument, ...options });
};
export const CanCancelOrderDocument = gql`
    query canCancelOrder($orderId: String!) {
  canCancelOrder(orderId: $orderId)
}
    `;

export function useCanCancelOrderQuery(options: Omit<Urql.UseQueryArgs<never, Types.CanCancelOrderQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.CanCancelOrderQuery>({ query: Types.CanCancelOrderDocument, ...options });
};
export const OwnOrderUpdateDocument = gql`
    mutation ownOrderUpdate($filter: OrderFilterInput!, $order: OrderEGiftUpdate, $token: String, $email: String) {
  ownOrderUpdate(filter: $filter, order: $order, token: $token, email: $email) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;

export function useOwnOrderUpdateMutation() {
  return Urql.useMutation<Types.OwnOrderUpdateMutation, Types.OwnOrderUpdateMutationVariables>(Types.OwnOrderUpdateDocument);
};
export const OwnOrderFindOneForThankyouDocument = gql`
    query ownOrderFindOneForThankyou($documentId: String!) {
  ownOrderFindOneForThankyou(documentId: $documentId) {
    ...OrderDetailForThankyou
  }
}
    ${OrderDetailForThankyouFragmentDoc}`;

export function useOwnOrderFindOneForThankyouQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnOrderFindOneForThankyouQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnOrderFindOneForThankyouQuery>({ query: Types.OwnOrderFindOneForThankyouDocument, ...options });
};
export const PublicPageOneDocument = gql`
    query publicPageOne($filter: FilterFindOnePageInput) {
  publicPageOne(filter: $filter) {
    ...PageFragment
  }
}
    ${PageFragmentFragmentDoc}`;

export function usePublicPageOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicPageOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicPageOneQuery>({ query: Types.PublicPageOneDocument, ...options });
};
export const PublicTopPageOneDocument = gql`
    query publicTopPageOne($filter: FilterFindOnePageInput) {
  publicPageOne(filter: $filter) {
    ...TopPageFragment
  }
}
    ${TopPageFragmentFragmentDoc}`;

export function usePublicTopPageOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicTopPageOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicTopPageOneQuery>({ query: Types.PublicTopPageOneDocument, ...options });
};
export const PublicPageFindPopularDocument = gql`
    query publicPageFindPopular($filter: FilterFindManyPageInput, $limit: Int) {
  publicPageFindPopular(filter: $filter, limit: $limit) {
    ...PageFindFragment
  }
}
    ${PageFindFragmentFragmentDoc}`;

export function usePublicPageFindPopularQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicPageFindPopularQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicPageFindPopularQuery>({ query: Types.PublicPageFindPopularDocument, ...options });
};
export const PublicPageFindDocument = gql`
    query publicPageFind($pageType: String, $page: Int!, $limit: Int!, $sort: SortPageInput) {
  publicPageFind(pageType: $pageType, page: $page, limit: $limit, sort: $sort) {
    count
    items {
      ...PageFindFragment
    }
    pageInfo {
      pageCount
    }
  }
}
    ${PageFindFragmentFragmentDoc}`;

export function usePublicPageFindQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicPageFindQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicPageFindQuery>({ query: Types.PublicPageFindDocument, ...options });
};
export const PublicRecommendInterviewPagesDocument = gql`
    query publicRecommendInterviewPages($siteId: String, $exceptPageId: String, $interviewIds: [String]) {
  publicRecommendInterviewPages(
    siteId: $siteId
    exceptPageId: $exceptPageId
    interviewIds: $interviewIds
  ) {
    items {
      document_id
      title
      description
      absolute_url
      is_status
      ogp_url
      supervisor {
        name
        belong
      }
    }
  }
}
    `;

export function usePublicRecommendInterviewPagesQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicRecommendInterviewPagesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicRecommendInterviewPagesQuery>({ query: Types.PublicRecommendInterviewPagesDocument, ...options });
};
export const PublicPagePartsManyDocument = gql`
    query PublicPagePartsMany($filter: FilterFindManyPagePartsInput) {
  publicPagePartsMany(filter: $filter) {
    crunch
    items {
      ...PagePartsFragment
    }
  }
}
    ${PagePartsFragmentFragmentDoc}`;

export function usePublicPagePartsManyQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicPagePartsManyQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicPagePartsManyQuery>({ query: Types.PublicPagePartsManyDocument, ...options });
};
export const PublicProductFindOneDocument = gql`
    query publicProductFindOne($filter: FilterFindOneProductInput) {
  publicProductFindOne(filter: $filter) {
    ...ProductFragment
  }
}
    ${ProductFragmentFragmentDoc}`;

export function usePublicProductFindOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicProductFindOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicProductFindOneQuery>({ query: Types.PublicProductFindOneDocument, ...options });
};
export const PublicProductFindManyDocument = gql`
    query publicProductFindMany($filter: FilterFindManyProductInput) {
  publicProductFindMany(filter: $filter) {
    ...ProductFragment
  }
}
    ${ProductFragmentFragmentDoc}`;

export function usePublicProductFindManyQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicProductFindManyQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicProductFindManyQuery>({ query: Types.PublicProductFindManyDocument, ...options });
};
export const PublicSearchSiteProductDocument = gql`
    query publicSearchSiteProduct($keywords: [String], $filter: FilterFindManyProductInput, $categoryId: String) {
  publicSearchSiteProduct(
    keywords: $keywords
    filter: $filter
    categoryId: $categoryId
  ) {
    ...ProductFragment
  }
}
    ${ProductFragmentFragmentDoc}`;

export function usePublicSearchSiteProductQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicSearchSiteProductQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicSearchSiteProductQuery>({ query: Types.PublicSearchSiteProductDocument, ...options });
};
export const ProductListDocument = gql`
    query ProductList($keywords: [String], $filter: FilterFindManyProductInput, $categoryId: String) {
  publicSearchSiteProduct(
    keywords: $keywords
    filter: $filter
    categoryId: $categoryId
  ) {
    document_id
    name
    slug
    material
    ranking_sale_order
    display_priority
    not_display_product_list
    color_type
    skus {
      document_id
      product_id
      site_id
      name
      main_image
      consumption_tax_percentage
      price
      compare_price
      color_code
      chip_image_url
      color
      size
      size_label
      size_supplement
      is_reservation
      is_restock_notice
      stock_quantity
      stock_quantity_limit
      egift_stock_quantity_limit
      net_stock_quantity
      net_stock_quantity_limit
    }
    product_category_ids
    product_categories {
      document_id
      name
      slug
      parent_category_id
      parent_category {
        name
        slug
      }
    }
    size_range
    promotion_label_ids
    promotion_labels {
      label_name
      document_id
      display_order_number
      background_color
      label_color
    }
    functionality_label_ids
    functionality_labels {
      label_name
      document_id
      display_order_number
    }
    sex
    fabric
    season
    wear_category
    is_general_medical_device
    is_quasi_drug
    top_image_alt
  }
}
    `;

export function useProductListQuery(options: Omit<Urql.UseQueryArgs<never, Types.ProductListQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.ProductListQuery>({ query: Types.ProductListDocument, ...options });
};
export const PublicProductRecommendDocument = gql`
    query publicProductRecommend($page: Int!, $limit: Int!, $sortOption: SortProductInput) {
  publicProductRecommend(page: $page, limit: $limit, sortOption: $sortOption) {
    items {
      ...ProductFragment
    }
    count
    pageInfo {
      hasNextPage
      pageCount
    }
  }
}
    ${ProductFragmentFragmentDoc}`;

export function usePublicProductRecommendQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicProductRecommendQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicProductRecommendQuery>({ query: Types.PublicProductRecommendDocument, ...options });
};
export const PublicProductRecommendOnlySideCartDocument = gql`
    query publicProductRecommendOnlySideCart($page: Int!, $limit: Int!, $sortOption: SortProductInput) {
  publicProductRecommend(page: $page, limit: $limit, sortOption: $sortOption) {
    items {
      ...RecommendProductFragment
    }
    count
    pageInfo {
      hasNextPage
      pageCount
    }
  }
}
    ${RecommendProductFragmentFragmentDoc}`;

export function usePublicProductRecommendOnlySideCartQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicProductRecommendOnlySideCartQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicProductRecommendOnlySideCartQuery>({ query: Types.PublicProductRecommendOnlySideCartDocument, ...options });
};
export const PublicProductFindManyByDocumentIdsDocument = gql`
    query publicProductFindManyByDocumentIds($documentIds: [String!]!, $passQuery: String) {
  publicProductFindManyByDocumentIds(
    documentIds: $documentIds
    passQuery: $passQuery
  ) {
    ...ProductFragment
  }
}
    ${ProductFragmentFragmentDoc}`;

export function usePublicProductFindManyByDocumentIdsQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicProductFindManyByDocumentIdsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicProductFindManyByDocumentIdsQuery>({ query: Types.PublicProductFindManyByDocumentIdsDocument, ...options });
};
export const PublicProductAllSearchDocument = gql`
    query publicProductAllSearch($input: ProductAllSearchInput!) {
  publicProductAllSearch(input: $input) {
    items {
      ...ProductFragment
    }
    count
    pageInfo {
      hasNextPage
      pageCount
    }
    filterCount {
      sex
      fabric
      season
      wear_category
      functionality_label_ids
      size
      filter_by_general_medical_device
      filter_by_in_stock
      filter_by_in_outlet
    }
  }
}
    ${ProductFragmentFragmentDoc}`;

export function usePublicProductAllSearchQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicProductAllSearchQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicProductAllSearchQuery>({ query: Types.PublicProductAllSearchDocument, ...options });
};
export const PublicProductCategoryFindOneDocument = gql`
    query publicProductCategoryFindOne($filter: FilterFindOneProductCategoryInput, $skip: Int, $sort: SortFindOneProductCategoryInput) {
  publicProductCategoryFindOne(filter: $filter, skip: $skip, sort: $sort) {
    ...ProductCategory
    child_categories {
      ...ChildCategories
    }
  }
}
    ${ProductCategoryFragmentDoc}
${ChildCategoriesFragmentDoc}`;

export function usePublicProductCategoryFindOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicProductCategoryFindOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicProductCategoryFindOneQuery>({ query: Types.PublicProductCategoryFindOneDocument, ...options });
};
export const PublicProductCategoryAllForSiteDocument = gql`
    query publicProductCategoryAllForSite($page: Int!, $limit: Int!, $filter: ProductCategoryFilterForSite, $parent_category_ids: [String!]) {
  publicProductCategoryAllForSite(
    page: $page
    limit: $limit
    filter: $filter
    parent_category_ids: $parent_category_ids
  ) {
    items {
      ...ParentCategoryWithChild
    }
    count
    pageInfo {
      hasNextPage
    }
  }
}
    ${ParentCategoryWithChildFragmentDoc}`;

export function usePublicProductCategoryAllForSiteQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicProductCategoryAllForSiteQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicProductCategoryAllForSiteQuery>({ query: Types.PublicProductCategoryAllForSiteDocument, ...options });
};
export const PublicProductCategoryOneBySlugDocument = gql`
    query publicProductCategoryOneBySlug($slug: String!, $siteId: String, $parentCategoryId: String) {
  publicProductCategoryOneBySlug(
    slug: $slug
    siteId: $siteId
    parentCategoryId: $parentCategoryId
  ) {
    ...ProductCategory
  }
}
    ${ProductCategoryFragmentDoc}`;

export function usePublicProductCategoryOneBySlugQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicProductCategoryOneBySlugQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicProductCategoryOneBySlugQuery>({ query: Types.PublicProductCategoryOneBySlugDocument, ...options });
};
export const PublicPromotionLabelFindManyDocument = gql`
    query publicPromotionLabelFindMany($filter: FilterFindManyPromotionLabelInput) {
  publicPromotionLabelFindMany(filter: $filter) {
    ...PromotionLabel
  }
}
    ${PromotionLabelFragmentDoc}`;

export function usePublicPromotionLabelFindManyQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicPromotionLabelFindManyQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicPromotionLabelFindManyQuery>({ query: Types.PublicPromotionLabelFindManyDocument, ...options });
};
export const OwnQuestionnaireOneDocument = gql`
    query ownQuestionnaireOne($filter: FilterFindOneQuestionnaireInput) {
  ownQuestionnaireOne(filter: $filter) {
    ...QuestionnaireFragment
  }
}
    ${QuestionnaireFragmentFragmentDoc}`;

export function useOwnQuestionnaireOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnQuestionnaireOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnQuestionnaireOneQuery>({ query: Types.OwnQuestionnaireOneDocument, ...options });
};
export const OwnQuestionnaireCreateDocument = gql`
    mutation ownQuestionnaireCreate($record: QuestionnaireCreateInput) {
  ownQuestionnaireCreate(record: $record) {
    ...QuestionnaireFragment
  }
}
    ${QuestionnaireFragmentFragmentDoc}`;

export function useOwnQuestionnaireCreateMutation() {
  return Urql.useMutation<Types.OwnQuestionnaireCreateMutation, Types.OwnQuestionnaireCreateMutationVariables>(Types.OwnQuestionnaireCreateDocument);
};
export const RestockReceptionCreateDocument = gql`
    mutation restockReceptionCreate($record: RestockReceptionCreateInput) {
  restockReceptionCreate(record: $record) {
    ...RestockReception
  }
}
    ${RestockReceptionFragmentDoc}`;

export function useRestockReceptionCreateMutation() {
  return Urql.useMutation<Types.RestockReceptionCreateMutation, Types.RestockReceptionCreateMutationVariables>(Types.RestockReceptionCreateDocument);
};
export const OwnRestockReceptionCreateDocument = gql`
    mutation ownRestockReceptionCreate($record: RestockReceptionCreateInput) {
  ownRestockReceptionCreate(record: $record) {
    ...RestockReception
  }
}
    ${RestockReceptionFragmentDoc}`;

export function useOwnRestockReceptionCreateMutation() {
  return Urql.useMutation<Types.OwnRestockReceptionCreateMutation, Types.OwnRestockReceptionCreateMutationVariables>(Types.OwnRestockReceptionCreateDocument);
};
export const PublicReturnOrExchangeRequestCreateDocument = gql`
    mutation publicReturnOrExchangeRequestCreate($record: ReturnOrExchangeRequestCreateInput!, $siteId: String, $sendMail: Boolean) {
  publicReturnOrExchangeRequestCreate(
    record: $record
    siteId: $siteId
    sendMail: $sendMail
  ) {
    ...ReturnOrExchangeRequest
  }
}
    ${ReturnOrExchangeRequestFragmentDoc}`;

export function usePublicReturnOrExchangeRequestCreateMutation() {
  return Urql.useMutation<Types.PublicReturnOrExchangeRequestCreateMutation, Types.PublicReturnOrExchangeRequestCreateMutationVariables>(Types.PublicReturnOrExchangeRequestCreateDocument);
};
export const PublicReturnOrExchangeRequestCountQuantityDocument = gql`
    query PublicReturnOrExchangeRequestCountQuantity($filter: FilterFindManyReturnOrExchangeRequestInput, $skuIds: [String!]) {
  publicReturnOrExchangeRequestCountQuantity(filter: $filter, skuIds: $skuIds) {
    sku_id
    request_quantity
  }
}
    `;

export function usePublicReturnOrExchangeRequestCountQuantityQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicReturnOrExchangeRequestCountQuantityQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicReturnOrExchangeRequestCountQuantityQuery>({ query: Types.PublicReturnOrExchangeRequestCountQuantityDocument, ...options });
};
export const OwnReturnOrExchangeRequestManyDocument = gql`
    query ownReturnOrExchangeRequestMany($filter: FilterFindManyReturnOrExchangeRequestInput) {
  ownReturnOrExchangeRequestMany(filter: $filter) {
    ...ReturnOrExchangeRequest
  }
}
    ${ReturnOrExchangeRequestFragmentDoc}`;

export function useOwnReturnOrExchangeRequestManyQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnReturnOrExchangeRequestManyQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnReturnOrExchangeRequestManyQuery>({ query: Types.OwnReturnOrExchangeRequestManyDocument, ...options });
};
export const OwnReturnOrExchangeRequestCancelDocument = gql`
    mutation ownReturnOrExchangeRequestCancel($documentId: String!) {
  ownReturnOrExchangeRequestCancel(documentId: $documentId)
}
    `;

export function useOwnReturnOrExchangeRequestCancelMutation() {
  return Urql.useMutation<Types.OwnReturnOrExchangeRequestCancelMutation, Types.OwnReturnOrExchangeRequestCancelMutationVariables>(Types.OwnReturnOrExchangeRequestCancelDocument);
};
export const PublicReviewPaginationDocument = gql`
    query publicReviewPagination($page: Int!, $limit: Int!, $filter: FilterFindManyReviewInput, $sort: SortFindManyReviewInput) {
  publicReviewPagination(
    page: $page
    perPage: $limit
    filter: $filter
    sort: $sort
  ) {
    count
    items {
      ...Review
    }
    pageInfo {
      currentPage
      perPage
      pageCount
      itemCount
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${ReviewFragmentDoc}`;

export function usePublicReviewPaginationQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicReviewPaginationQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicReviewPaginationQuery>({ query: Types.PublicReviewPaginationDocument, ...options });
};
export const OwnReviewCreateDocument = gql`
    mutation ownReviewCreate($record: ReviewCreateInput!, $isAnonymous: Boolean!) {
  ownReviewCreate(record: $record, isAnonymous: $isAnonymous) {
    recordId
    record {
      ...Review
    }
    error {
      message
    }
  }
}
    ${ReviewFragmentDoc}`;

export function useOwnReviewCreateMutation() {
  return Urql.useMutation<Types.OwnReviewCreateMutation, Types.OwnReviewCreateMutationVariables>(Types.OwnReviewCreateDocument);
};
export const PublicReviewGroupOneDocument = gql`
    query publicReviewGroupOne($filter: FilterFindOneReviewGroupInput) {
  publicReviewGroupOne(filter: $filter) {
    ...ReviewGroup
  }
}
    ${ReviewGroupFragmentDoc}`;

export function usePublicReviewGroupOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicReviewGroupOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicReviewGroupOneQuery>({ query: Types.PublicReviewGroupOneDocument, ...options });
};
export const IsReviewGroupInFootCategoryDocument = gql`
    query isReviewGroupInFootCategory($reviewGroupId: String!) {
  isReviewGroupInFootCategory(reviewGroupId: $reviewGroupId)
}
    `;

export function useIsReviewGroupInFootCategoryQuery(options: Omit<Urql.UseQueryArgs<never, Types.IsReviewGroupInFootCategoryQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.IsReviewGroupInFootCategoryQuery>({ query: Types.IsReviewGroupInFootCategoryDocument, ...options });
};
export const RouteLogCreateDocument = gql`
    mutation routeLogCreate($record: [RouteLogCreateInput]) {
  routeLogCreate(record: $record) {
    ...RouteLog
  }
}
    ${RouteLogFragmentDoc}`;

export function useRouteLogCreateMutation() {
  return Urql.useMutation<Types.RouteLogCreateMutation, Types.RouteLogCreateMutationVariables>(Types.RouteLogCreateDocument);
};
export const SiteOneDocument = gql`
    query SiteOne($filter: FilterFindOneSiteInput, $skip: Int, $sort: SortFindOneSiteInput) {
  siteOne(filter: $filter, skip: $skip, sort: $sort) {
    ...Site
  }
}
    ${SiteFragmentDoc}`;

export function useSiteOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.SiteOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.SiteOneQuery>({ query: Types.SiteOneDocument, ...options });
};
export const SiteOneOnlySettingTermsTextDocument = gql`
    query SiteOneOnlySettingTermsText($filter: FilterFindOneSiteInput, $skip: Int, $sort: SortFindOneSiteInput) {
  siteOne(filter: $filter, skip: $skip, sort: $sort) {
    document_id
    slug
    settings {
      terms_text
    }
  }
}
    `;

export function useSiteOneOnlySettingTermsTextQuery(options: Omit<Urql.UseQueryArgs<never, Types.SiteOneOnlySettingTermsTextQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.SiteOneOnlySettingTermsTextQuery>({ query: Types.SiteOneOnlySettingTermsTextDocument, ...options });
};
export const SiteOneOnlySettingTextDocument = gql`
    query SiteOneOnlySettingText($filter: FilterFindOneSiteInput, $skip: Int, $sort: SortFindOneSiteInput) {
  siteOne(filter: $filter, skip: $skip, sort: $sort) {
    document_id
    slug
    settings {
      terms_text
      schedule_delivery_date_note
      confirm_attention_note
      confirm_attention_available
      gift_note
      coupon_note
    }
  }
}
    `;

export function useSiteOneOnlySettingTextQuery(options: Omit<Urql.UseQueryArgs<never, Types.SiteOneOnlySettingTextQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.SiteOneOnlySettingTextQuery>({ query: Types.SiteOneOnlySettingTextDocument, ...options });
};
export const PublicSkuFindAllDocument = gql`
    query publicSkuFindAll($skuIds: [String]!, $isEGift: Boolean, $siteId: String) {
  skuPublicMany(skuIds: $skuIds, siteId: $siteId, isEGift: $isEGift) {
    ...SkuFragment
  }
}
    ${SkuFragmentFragmentDoc}`;

export function usePublicSkuFindAllQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicSkuFindAllQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicSkuFindAllQuery>({ query: Types.PublicSkuFindAllDocument, ...options });
};
export const PublicSkuFindManyDocument = gql`
    query publicSkuFindMany($filter: FilterFindManySkuInput, $limit: Int) {
  publicSkuFindMany(filter: $filter, limit: $limit) {
    ...SkuFragment
  }
}
    ${SkuFragmentFragmentDoc}`;

export function usePublicSkuFindManyQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicSkuFindManyQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicSkuFindManyQuery>({ query: Types.PublicSkuFindManyDocument, ...options });
};
export const PublicSkuFindManyWithProductDocument = gql`
    query publicSkuFindManyWithProduct($filter: FilterFindManySkuInput) {
  publicSkuFindMany(filter: $filter) {
    ...SkuWithProduct
  }
}
    ${SkuWithProductFragmentDoc}`;

export function usePublicSkuFindManyWithProductQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicSkuFindManyWithProductQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicSkuFindManyWithProductQuery>({ query: Types.PublicSkuFindManyWithProductDocument, ...options });
};
export const PublicSkuFindOneDocument = gql`
    query publicSkuFindOne($filter: FilterFindOneSkuInput) {
  publicSkuFindOne(filter: $filter) {
    ...SkuFragment
  }
}
    ${SkuFragmentFragmentDoc}`;

export function usePublicSkuFindOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicSkuFindOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicSkuFindOneQuery>({ query: Types.PublicSkuFindOneDocument, ...options });
};
export const OwnPurchasedProductSkuSearchDocument = gql`
    query ownPurchasedProductSkuSearch($page: Int!, $limit: Int!) {
  ownPurchasedProductSkuSearch(page: $page, limit: $limit) {
    count
    items {
      ...PurchasedProductSku
    }
    pageInfo {
      hasNextPage
      pageCount
    }
  }
}
    ${PurchasedProductSkuFragmentDoc}`;

export function useOwnPurchasedProductSkuSearchQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnPurchasedProductSkuSearchQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnPurchasedProductSkuSearchQuery>({ query: Types.OwnPurchasedProductSkuSearchDocument, ...options });
};
export const OwnPurchasedSkuFindOneDocument = gql`
    query ownPurchasedSkuFindOne($skuId: String!) {
  ownPurchasedSkuFindOne(skuId: $skuId) {
    document_id
    size
    image_url
    product_id
    product_name
    is_reviewable
    is_general_medical_device
    has_reviewed
    can_review
    is_foot_category
    ordered_skus {
      sku_id
      size
    }
  }
}
    `;

export function useOwnPurchasedSkuFindOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnPurchasedSkuFindOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnPurchasedSkuFindOneQuery>({ query: Types.OwnPurchasedSkuFindOneDocument, ...options });
};
export const PublicStockFindManyByJanCodeDocument = gql`
    query publicStockFindManyByJanCode($managementJanCode: String, $offlineRelatedJanCodes: String) {
  publicStockFindManyByJanCode(
    managementJanCode: $managementJanCode
    offlineRelatedJanCodes: $offlineRelatedJanCodes
  ) {
    ...Stock
  }
}
    ${StockFragmentDoc}`;

export function usePublicStockFindManyByJanCodeQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicStockFindManyByJanCodeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicStockFindManyByJanCodeQuery>({ query: Types.PublicStockFindManyByJanCodeDocument, ...options });
};
export const PublicStoreAllDocument = gql`
    query publicStoreAll($filter: FilterFindManyStoreInput, $sort: SortFindManyStoreInput) {
  publicStoreAll(filter: $filter, sort: $sort) {
    ...Store
  }
}
    ${StoreFragmentDoc}`;

export function usePublicStoreAllQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicStoreAllQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicStoreAllQuery>({ query: Types.PublicStoreAllDocument, ...options });
};
export const PublicStorePaginationDocument = gql`
    query publicStorePagination($page: Int!, $perPage: Int!, $filter: FilterFindManyStoreInput, $sort: SortFindManyStoreInput) {
  publicStorePagination(
    page: $page
    perPage: $perPage
    filter: $filter
    sort: $sort
  ) {
    items {
      ...Store
    }
    count
    pageInfo {
      hasNextPage
      pageCount
    }
  }
}
    ${StoreFragmentDoc}`;

export function usePublicStorePaginationQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicStorePaginationQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicStorePaginationQuery>({ query: Types.PublicStorePaginationDocument, ...options });
};
export const PublicStoreSearchDocument = gql`
    query publicStoreSearch($page: Int!, $perPage: Int!, $filter: StoreSearchFilter) {
  publicStoreSearch(page: $page, perPage: $perPage, filter: $filter) {
    items {
      ...Store
    }
    allCount
    count
    pageInfo {
      hasNextPage
      pageCount
    }
  }
}
    ${StoreFragmentDoc}`;

export function usePublicStoreSearchQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicStoreSearchQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicStoreSearchQuery>({ query: Types.PublicStoreSearchDocument, ...options });
};
export const PublicStoreFilterDocument = gql`
    query publicStoreFilter($filter: StoreFilter) {
  publicStoreFilter(filter: $filter) {
    ...Store
  }
}
    ${StoreFragmentDoc}`;

export function usePublicStoreFilterQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicStoreFilterQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicStoreFilterQuery>({ query: Types.PublicStoreFilterDocument, ...options });
};
export const PublicStoreOneDocument = gql`
    query publicStoreOne($filter: FilterFindOneStoreInput) {
  publicStoreOne(filter: $filter) {
    ...Store
  }
}
    ${StoreFragmentDoc}`;

export function usePublicStoreOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicStoreOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicStoreOneQuery>({ query: Types.PublicStoreOneDocument, ...options });
};
export const PublicSurveyQuestionFindOneDocument = gql`
    query publicSurveyQuestionFindOne($survey_question_id: String!, $order_id: String) {
  publicSurveyQuestionFindOne(
    survey_question_id: $survey_question_id
    order_id: $order_id
  ) {
    is_answered
    survey_question {
      ...SurveyQuestionFragment
    }
  }
}
    ${SurveyQuestionFragmentFragmentDoc}`;

export function usePublicSurveyQuestionFindOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.PublicSurveyQuestionFindOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.PublicSurveyQuestionFindOneQuery>({ query: Types.PublicSurveyQuestionFindOneDocument, ...options });
};
export const CreateSurveyAnswersDocument = gql`
    mutation createSurveyAnswers($input: CreateSurveyAnswersInput!, $order_id: String) {
  createSurveyAnswers(input: $input, order_id: $order_id)
}
    `;

export function useCreateSurveyAnswersMutation() {
  return Urql.useMutation<Types.CreateSurveyAnswersMutation, Types.CreateSurveyAnswersMutationVariables>(Types.CreateSurveyAnswersDocument);
};
export const OwnUserOneDocument = gql`
    query OwnUserOne {
  ownUserOne {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useOwnUserOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnUserOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnUserOneQuery>({ query: Types.OwnUserOneDocument, ...options });
};
export const AllChangeEmailOneDocument = gql`
    query AllChangeEmailOne($pass: String!) {
  allChangeEmailOne(pass: $pass) {
    document_id
    email
    new_email
  }
}
    `;

export function useAllChangeEmailOneQuery(options: Omit<Urql.UseQueryArgs<never, Types.AllChangeEmailOneQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.AllChangeEmailOneQuery>({ query: Types.AllChangeEmailOneDocument, ...options });
};
export const SiteUserSignUpDocument = gql`
    mutation SiteUserSignUp($site_slug: String!, $email: String!, $password: String!, $receiveMail: EnumUserReceive_mail) {
  siteUserSignUp(
    site_slug: $site_slug
    email: $email
    password: $password
    receiveMail: $receiveMail
  ) {
    ...UserForSign
  }
}
    ${UserForSignFragmentDoc}`;

export function useSiteUserSignUpMutation() {
  return Urql.useMutation<Types.SiteUserSignUpMutation, Types.SiteUserSignUpMutationVariables>(Types.SiteUserSignUpDocument);
};
export const SiteUserSignInDocument = gql`
    mutation SiteUserSignIn($site_slug: String!, $email: String!, $password: String!) {
  siteUserSignIn(site_slug: $site_slug, email: $email, password: $password) {
    ...UserForSign
  }
}
    ${UserForSignFragmentDoc}`;

export function useSiteUserSignInMutation() {
  return Urql.useMutation<Types.SiteUserSignInMutation, Types.SiteUserSignInMutationVariables>(Types.SiteUserSignInDocument);
};
export const SignInTokenRefreshDocument = gql`
    mutation signInTokenRefresh($refreshToken: String!) {
  signInTokenRefresh(refreshToken: $refreshToken) {
    ...UserForSign
  }
}
    ${UserForSignFragmentDoc}`;

export function useSignInTokenRefreshMutation() {
  return Urql.useMutation<Types.SignInTokenRefreshMutation, Types.SignInTokenRefreshMutationVariables>(Types.SignInTokenRefreshDocument);
};
export const SiteSocialSignUpDocument = gql`
    mutation SiteSocialSignUp($site_slug: String!, $email: String!, $password: String, $socialId: String!, $receiveMail: EnumUserReceive_mail) {
  siteSocialSignUp(
    site_slug: $site_slug
    email: $email
    password: $password
    socialId: $socialId
    receiveMail: $receiveMail
  ) {
    ...UserForSign
  }
}
    ${UserForSignFragmentDoc}`;

export function useSiteSocialSignUpMutation() {
  return Urql.useMutation<Types.SiteSocialSignUpMutation, Types.SiteSocialSignUpMutationVariables>(Types.SiteSocialSignUpDocument);
};
export const SiteSocialSignDocument = gql`
    mutation SiteSocialSign($site_slug: String!, $token: String!) {
  siteSocialSign(site_slug: $site_slug, token: $token) {
    socialId
    is_login
    profileInfo {
      user_name
      image_url
      bio
      gender
      birthday
      location
      prefecture
      city
      street
      postal_code
      first_name
      first_name_kana
      last_name
      last_name_kana
      middle_name
      full_name
      friendship_status
      friendship_status_updated_at
    }
    emailInfo {
      email
    }
    phoneInfo {
      number
    }
    user {
      email
      document_id
      access_token
      receive_mail
      refresh_token
    }
  }
}
    `;

export function useSiteSocialSignMutation() {
  return Urql.useMutation<Types.SiteSocialSignMutation, Types.SiteSocialSignMutationVariables>(Types.SiteSocialSignDocument);
};
export const OwnUserMappingLineDocument = gql`
    mutation OwnUserMappingLine($site_slug: String!, $token: String!) {
  ownUserMappingLine(site_slug: $site_slug, token: $token)
}
    `;

export function useOwnUserMappingLineMutation() {
  return Urql.useMutation<Types.OwnUserMappingLineMutation, Types.OwnUserMappingLineMutationVariables>(Types.OwnUserMappingLineDocument);
};
export const OwnUserUpdateDocument = gql`
    mutation OwnUserUpdate($filter: OwnUserUpdateInput) {
  ownUserUpdate(filter: $filter) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useOwnUserUpdateMutation() {
  return Urql.useMutation<Types.OwnUserUpdateMutation, Types.OwnUserUpdateMutationVariables>(Types.OwnUserUpdateDocument);
};
export const OwnUserChangeEmailDocument = gql`
    mutation OwnUserChangeEmail($newEmail: String!) {
  ownUserChangeEmail(newEmail: $newEmail)
}
    `;

export function useOwnUserChangeEmailMutation() {
  return Urql.useMutation<Types.OwnUserChangeEmailMutation, Types.OwnUserChangeEmailMutationVariables>(Types.OwnUserChangeEmailDocument);
};
export const OwnUserUpdateEmailDocument = gql`
    mutation OwnUserUpdateEmail($newEmail: String!, $token: String!) {
  ownUserUpdateEmail(newEmail: $newEmail, token: $token)
}
    `;

export function useOwnUserUpdateEmailMutation() {
  return Urql.useMutation<Types.OwnUserUpdateEmailMutation, Types.OwnUserUpdateEmailMutationVariables>(Types.OwnUserUpdateEmailDocument);
};
export const OwnUserUpdatePasswordDocument = gql`
    mutation OwnUserUpdatePassword($currentPassword: String!, $newPassword: String!) {
  ownUserUpdatePassword(
    currentPassword: $currentPassword
    newPassword: $newPassword
  )
}
    `;

export function useOwnUserUpdatePasswordMutation() {
  return Urql.useMutation<Types.OwnUserUpdatePasswordMutation, Types.OwnUserUpdatePasswordMutationVariables>(Types.OwnUserUpdatePasswordDocument);
};
export const UserResetPasswordDocument = gql`
    mutation UserResetPassword($email: String!) {
  userResetPassword(email: $email)
}
    `;

export function useUserResetPasswordMutation() {
  return Urql.useMutation<Types.UserResetPasswordMutation, Types.UserResetPasswordMutationVariables>(Types.UserResetPasswordDocument);
};
export const UserUpdatePasswordDocument = gql`
    mutation UserUpdatePassword($email: String!, $password: String!, $token: String!) {
  userUpdatePassword(email: $email, password: $password, token: $token)
}
    `;

export function useUserUpdatePasswordMutation() {
  return Urql.useMutation<Types.UserUpdatePasswordMutation, Types.UserUpdatePasswordMutationVariables>(Types.UserUpdatePasswordDocument);
};
export const ExistUserByEmailDocument = gql`
    query ExistUserByEmail($email: String!, $siteId: String) {
  existUserByEmail(email: $email, siteId: $siteId)
}
    `;

export function useExistUserByEmailQuery(options: Omit<Urql.UseQueryArgs<never, Types.ExistUserByEmailQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.ExistUserByEmailQuery>({ query: Types.ExistUserByEmailDocument, ...options });
};
export const LinkUserToStoreDocument = gql`
    mutation linkUserToStore($externalServiceId: String!, $storeId: String!) {
  linkUserToStore(externalServiceId: $externalServiceId, storeId: $storeId)
}
    `;

export function useLinkUserToStoreMutation() {
  return Urql.useMutation<Types.LinkUserToStoreMutation, Types.LinkUserToStoreMutationVariables>(Types.LinkUserToStoreDocument);
};
export const CreateSmaregiCustomerDocument = gql`
    mutation createSmaregiCustomer($userId: String, $externalServiceId: String) {
  createSmaregiCustomer(userId: $userId, externalServiceId: $externalServiceId)
}
    `;

export function useCreateSmaregiCustomerMutation() {
  return Urql.useMutation<Types.CreateSmaregiCustomerMutation, Types.CreateSmaregiCustomerMutationVariables>(Types.CreateSmaregiCustomerDocument);
};
export const OwnUserOneForMyPageTopDocument = gql`
    query ownUserOneForMyPageTop {
  ownUserOneForMyPageTop {
    ...UserDetail
  }
}
    ${UserDetailFragmentDoc}`;

export function useOwnUserOneForMyPageTopQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnUserOneForMyPageTopQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnUserOneForMyPageTopQuery>({ query: Types.OwnUserOneForMyPageTopDocument, ...options });
};
export const UserAddressCreateDocument = gql`
    mutation UserAddressCreate($userAddress: UserAddressCreateInput) {
  userAddressCreate(userAddress: $userAddress) {
    ...UserAddressFragment
  }
}
    ${UserAddressFragmentFragmentDoc}`;

export function useUserAddressCreateMutation() {
  return Urql.useMutation<Types.UserAddressCreateMutation, Types.UserAddressCreateMutationVariables>(Types.UserAddressCreateDocument);
};
export const UserAddressUpdateDocument = gql`
    mutation UserAddressUpdate($record: UpdateOneUserAddressInput!, $filter: FilterUpdateOneUserAddressInput) {
  userAddressUpdate(record: $record, filter: $filter) {
    record {
      ...UserAddressFragment
    }
  }
}
    ${UserAddressFragmentFragmentDoc}`;

export function useUserAddressUpdateMutation() {
  return Urql.useMutation<Types.UserAddressUpdateMutation, Types.UserAddressUpdateMutationVariables>(Types.UserAddressUpdateDocument);
};
export const UserAddressRemoveDocument = gql`
    mutation UserAddressRemove($filter: FilterRemoveOneUserAddressInput) {
  userAddressRemove(filter: $filter) {
    record {
      ...UserAddressFragment
    }
    error {
      message
      ... on MongoError {
        code
      }
    }
  }
}
    ${UserAddressFragmentFragmentDoc}`;

export function useUserAddressRemoveMutation() {
  return Urql.useMutation<Types.UserAddressRemoveMutation, Types.UserAddressRemoveMutationVariables>(Types.UserAddressRemoveDocument);
};
export const OwnUserCouponCountDocument = gql`
    query ownUserCouponCount($filter: FilterCountUserCouponInput) {
  ownUserCouponCount(filter: $filter)
}
    `;

export function useOwnUserCouponCountQuery(options: Omit<Urql.UseQueryArgs<never, Types.OwnUserCouponCountQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<Types.OwnUserCouponCountQuery>({ query: Types.OwnUserCouponCountDocument, ...options });
};
export const OwnAddEventCouponDocument = gql`
    mutation ownAddEventCoupon($siteId: String, $couponEvent: String) {
  ownAddEventCoupon(siteId: $siteId, couponEvent: $couponEvent) {
    ...UserCouponTypeFragment
  }
}
    ${UserCouponTypeFragmentFragmentDoc}`;

export function useOwnAddEventCouponMutation() {
  return Urql.useMutation<Types.OwnAddEventCouponMutation, Types.OwnAddEventCouponMutationVariables>(Types.OwnAddEventCouponDocument);
};
export const OwnAddAcquisitionCouponDocument = gql`
    mutation ownAddAcquisitionCoupon($siteId: String, $couponId: String) {
  ownAddAcquisitionCoupon(siteId: $siteId, couponId: $couponId) {
    ...UserCouponTypeFragment
  }
}
    ${UserCouponTypeFragmentFragmentDoc}`;

export function useOwnAddAcquisitionCouponMutation() {
  return Urql.useMutation<Types.OwnAddAcquisitionCouponMutation, Types.OwnAddAcquisitionCouponMutationVariables>(Types.OwnAddAcquisitionCouponDocument);
};
export const OwnAddExchangeMileCouponDocument = gql`
    mutation ownAddExchangeMileCoupon($mileageExpense: Int!) {
  ownAddExchangeMileCoupon(mileageExpense: $mileageExpense)
}
    `;

export function useOwnAddExchangeMileCouponMutation() {
  return Urql.useMutation<Types.OwnAddExchangeMileCouponMutation, Types.OwnAddExchangeMileCouponMutationVariables>(Types.OwnAddExchangeMileCouponDocument);
};
export const UserExecWithdrawalDocument = gql`
    mutation userExecWithdrawal($record: WithdrawalSheetCreateInput!) {
  userExecWithdrawal(record: $record)
}
    `;

export function useUserExecWithdrawalMutation() {
  return Urql.useMutation<Types.UserExecWithdrawalMutation, Types.UserExecWithdrawalMutationVariables>(Types.UserExecWithdrawalDocument);
};