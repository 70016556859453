<template>
  <section :class="$style.error">
    <template v-if="error && 'statusCode' in error">
      <h2 :class="$style.status_code">{{ error?.statusCode }}</h2>
      <template v-if="error?.statusCode === 404">
        <h1>お探しのページが見つかりません</h1>
        <div>
          <p>ご不便をおかけし申し訳ございません。</p>
          <p>正常にご覧いただけるよう、解決に取り組んでおります。</p>
          <p>繰り返し同様の操作をしても改善されない場合、お手数ですがサポートまでお問い合わせ下さい。</p>
        </div>
        <AtomsConsumerLink url="/">
          <AtomsConsumerButton variant="secondary">トップページへ</AtomsConsumerButton>
        </AtomsConsumerLink>
      </template>
      <template v-else-if="error?.statusCode === 403">
        <h1>403 Forbidden</h1>
        <p>このページにアクセスする権限がありません</p>
        <AtomsConsumerLink url="/sign_in">
          <AtomsConsumerButton variant="secondary">別のユーザーでログインする</AtomsConsumerButton>
        </AtomsConsumerLink>
      </template>
      <template v-else>
        <h1>{{ error?.statusCode }}</h1>
        <p>エラーが発生しました。</p>
        <p>{{ error?.statusMessage }}</p>
        <p>{{ error?.message }}</p>
      </template>
    </template>
    <template v-else>
      <p>予期せぬエラーが発生しました。</p>
      <p>{{ error?.message }}</p>
    </template>
  </section>
</template>

<script setup lang="ts">
const error = useError()

const { data: newrelic } = await useFetch("/api/newrelic")
useHead({
  script: [
    {
      innerHTML: newrelic.value ?? "",
    },
  ],
})

onMounted(() => {
  const statusCode = error.value && "statusCode" in error.value ? error.value?.statusCode : ""
  waitForNREUM().then((nreum) => {
    nreum.noticeError(catchGuard(error.value), {
      // エラーページ表示識別可能なようにパラメーター設定
      errorPageDisplay: 1,
      errorPageStatusCode: statusCode,
    })
  })

  // webpack error
  if (/^Loading chunk (\d)+ failed\./.test(error.value?.message ?? "") && window.location.hash !== "#retry") {
    window.location.hash = "#retry"
    window.location.reload()
  }
})
</script>

<style module scoped lang="scss">
.error {
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  .status_code {
    @include main-font;
    font-size: 6rem;
    color: $secondary-60;
  }
  h1 {
    @include main-font;
    font-size: 1.5rem;
    color: $secondary;
    font-weight: bold;
  }
}
</style>
