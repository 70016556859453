import { ProductFragmentFragment } from "~/types/type.generated"
import { PagePartsProductType, PagePartsProductInProducts } from "~/types/product"
import { isSkuOutOfStock } from "~/utils/sku"

type CategoryArgs = {
  parent_category?: { name?: string | undefined; slug: string }
  slug?: string
}

const createCategoryUrl = (productCategory: CategoryArgs | null | undefined): string => {
  let url = "/"
  if (productCategory?.parent_category) url += `${productCategory.parent_category.slug}/`
  if (productCategory?.slug) url += productCategory.slug
  return url
}

export const getSiteProductCategoryUrl = (category: CategoryArgs): string => {
  const productCategoryUrl = createCategoryUrl(category)
  return productCategoryUrl
}

type GetSiteProductPageUrlProduct = Pick<ProductFragmentFragment, "slug"> & {
  product_category_ids?: ProductFragmentFragment["product_category_ids"]
  product_categories?: ProductFragmentFragment["product_categories"]
}

export const sortCategories = (
  categories: ProductFragmentFragment["product_categories"],
  category_ids: ProductFragmentFragment["product_category_ids"],
): ProductFragmentFragment["product_categories"] => {
  const productCategoryIds = category_ids ?? []
  if (productCategoryIds && categories && categories.length > 0) {
    // TODO: mongoでproduct_categoriesの配列の順番を担保できないので、product_category_idsと同じになるようにソートする.本当はmongo側でソートしたい
    return categories.sort(
      (a, b) => productCategoryIds.indexOf(a.document_id) - productCategoryIds.indexOf(b.document_id),
    )
  }
  return []
}

export const getSiteProductPageUrl = (product: GetSiteProductPageUrlProduct): string => {
  const productCategoryIds = product.product_category_ids ?? []
  if (product.product_categories && product.product_categories.length > 0) {
    product.product_categories = sortCategories(product.product_categories, productCategoryIds)
    return `${createCategoryUrl(product.product_categories[0])}/${product.slug}`
  }
  return `/products/${product.slug}`
}

export const canDisplayProduct = (
  product: Pick<ProductFragmentFragment, "skus" | "not_display_product_list">,
): boolean => {
  return product?.skus?.[0] && !product.not_display_product_list
}

export const isAllOutOfStock = (
  product: ProductFragmentFragment | PagePartsProductType | PagePartsProductInProducts,
): boolean => {
  return product.skus.every((sku) => isSkuOutOfStock(sku))
}

/**
 * promotion_labels が存在するか、全てのSKUが在庫切れの場合にtrueを返す
 */
export const isDisplayLabels = (
  product: ProductFragmentFragment | PagePartsProductType | PagePartsProductInProducts,
): boolean => {
  return (product.promotion_labels && !!product.promotion_labels.length) || isAllOutOfStock(product)
}
