import { defineStore } from "pinia"
import { CartItem } from "@tential/ec-gql-schema/models/order"
import { ShippingReferredType } from "@tential/ec-gql-schema/master/order"
import { ContactCategoryKeys, SubCategoryListKeys } from "@tential/ec-gql-schema/master/contact"
import {
  EGiftWithOrderFragment,
  OrderItems,
  Coupon,
  PublicSearchSiteProductQuery,
  CouponWithUserCouponIdFragment,
  ReviewCreateInput,
} from "~/types/type.generated"
import { EGiftSelect, EGiftSkuByProduct, ShippingInfo, SkuByProduct } from "~/types/eGift"
import { ContactStoreState } from "~/types/contact"
import { RouteLog } from "~/types/routeLog"
import { AdTrackingParams } from "~/types/ad"
import { format2CartItem } from "~/composables/functions"
import { cloneDeep } from "~/utils/functions/common"
import {} from "pinia-plugin-persistedstate"
import { initOrderFormData } from "~/utils/const/order"
import { initReviewFormData } from "~/utils/const/review"
import { OrderForm } from "~/types/order"
import { InnerArray } from "~/types/utility"
import { UsedCouponType } from "~/types/payment"

/**
 * 商品詳細ページ(pages/[categorySlug]/[subCategorySlug]/[productSlug])
 * で商品の取得に使用するクエリの型
 */
export type Product = InnerArray<PublicSearchSiteProductQuery["publicSearchSiteProduct"]>

export const state = () => ({
  cartList: Array<CartItem>(),
  cartListBySite: Array<{
    siteId: string
  }>(),
  temporaryCartList: Array<CartItem>(),
  stashCartList: Array<CartItem>(),
  contact: {
    name: "",
    email: "",
    confirmEmail: "",
    channel: "",
    type_id: 0,
    type_name: "",
    category: "before_order" as ContactCategoryKeys,
    sub_category: "about_the_product" as SubCategoryListKeys,
    support_status: "",
    product_name: "",
    order_id: "",
    content: "",
    files: Array<File>(),
    site_id: "",
    local_user_id: "",
    image_urls: Array<string>(),
    is_product_guide: false,
  },
  routeLogs: Array<RouteLog>(),
  purchaseEventSent: false,
  productBrowsingHistory: Array<Product>(),
  order: {
    document_id: "",
    total: 0,
    form: initOrderFormData(),
  },
  usedCoupons: Array<UsedCouponType>(),
  coupon: {
    couponCode: "",
    expired: 0,
    myCoupon: null as CouponWithUserCouponIdFragment | null,
  },
  distributedCoupon: {
    coupons: Array<Omit<Coupon, "_id">>(),
    isNotModal: false,
  },
  user: {
    isSigned: false,
    document_id: "",
    site_id: ("" as string) || null,
    data: {},
    email: "",
    token: "",
  },
  headerRef: "",
  trackingParams: {
    ad_url: "",
    to_url: "",
  },
  trafficParams: {
    full_path: "",
  },
  switchFalseEveryFeatureFlag: false,
  socialId: "",
  socialSignUpReceiveMail: false,
  eGift: {
    message: "",
    giver_name: "",
    is_select: false,
    isAnonymous: false,
  },
  eGiftData: {} as EGiftWithOrderFragment,
  eGiftOrderItems: Array<OrderItems>(),
  eGiftShippingInfo: {
    shipping_referred_type: "standard_delivery" as (typeof ShippingReferredType)[number],
    shipping_referred_date: "",
    shipping_referred_time: "",
    scheduled_shipping_date: "",
  },
  eGiftSkuByProducts: {
    eGift_id: "",
    skuByProducts: Array<SkuByProduct>(),
  },
  orderForm: initOrderFormData(),
  reviewForm: initReviewFormData(),
})

export type RootState = ReturnType<typeof state>

interface UserPayload {
  document_id: string
  site_id?: string
  data?: Record<string, unknown>
  email?: string
  token?: string
  isSigned?: boolean
}

export const getters = {
  cartList: (state: RootState) => state.cartList,
  routeLogs: (state: RootState) => state.routeLogs,
  purchaseEventSent: (state: RootState) => state.purchaseEventSent,
  productBrowsingHistory: (state: RootState) => state.productBrowsingHistory,
  getTrackingParams: (state: RootState) => state.trackingParams,
  getTrafficParams: (state: RootState) => state.trafficParams,
  getContact: (state: RootState) => state.contact,
  getOrder: (state: RootState) => state.order,
  usedCoupons: (state: RootState) => state.usedCoupons,
  setCouponCode: (state: RootState) => state.coupon,
  getDistributedCoupon: (state: RootState) => state.distributedCoupon,
  isSigned: (state: RootState) => state.user.isSigned,
  userId: (state: RootState) => state.user.document_id,
  getUser: (state: RootState) => state.user,
  getSwitchFalseEveryFeatureFlag: (state: RootState) => state.switchFalseEveryFeatureFlag,
  getSocialId: (state: RootState) => state.socialId,
  getSocialSignUpReceiveMail: (state: RootState) => state.socialSignUpReceiveMail,
  getHeaderRef: (state: RootState) => state.headerRef,
  eGift: (state: RootState) => state.eGift,
  eGiftData: (state: RootState) => state.eGiftData,
  eGiftOrderItems: (state: RootState) => state.eGiftOrderItems,
  eGiftShippingInfo: (state: RootState) => state.eGiftShippingInfo,
  eGiftSkuByProducts: (state: RootState) => state.eGiftSkuByProducts,
  orderForm: (state: RootState) => state.orderForm,
}

const injectSameItem = (list: CartItem[], item: CartItem): CartItem[] => {
  let sameItemIndex = null
  const sameItem = list.find((_item, _index) => {
    if (
      _item.sku_id === item.sku_id &&
      _item.product_id === item.product_id &&
      _item.gift?.sku_id === item.gift?.sku_id &&
      _item.gift?.message === item.gift?.message &&
      _item.gift?.card === item.gift?.card &&
      _item.embroidery?.text === item.embroidery?.text &&
      _item.embroidery?.font === item.embroidery?.font &&
      _item.embroidery?.color === item.embroidery?.color &&
      _item.embroidery?.position === item.embroidery?.position
    ) {
      sameItemIndex = _index
      return true
    }

    return false
  })

  if (sameItemIndex !== null && sameItem) {
    const index = sameItemIndex + 1
    list.splice(index, 0, sameItem)

    return list
  } else {
    return [...list, item]
  }
}

export const useStore = defineStore({
  id: "root",
  state,
  actions: {
    // カートリストに商品(SKU)を追加
    addCartList(cartItem: CartItem): void {
      this.cartList = injectSameItem(this.cartList, cloneDeep(cartItem))
    },

    replaceTemporaryCartList(cartList: CartItem[]): void {
      this.temporaryCartList = cartList
    },

    initializeTemporaryCartList(): void {
      this.temporaryCartList = []
    },

    replaceTemporaryCartListDispatch({
      item,
      quantity,
    }: {
      item: {
        productId: string
        skuId: string
        siteId: string
        gift: any
        isReservation: boolean
      }
      quantity: number
    }) {
      const cartItem = format2CartItem({
        product_id: item.productId,
        sku_id: item.skuId,
        site_id: item.siteId,
        gift: item.gift,
        is_reservation: item.isReservation,
      })
      const cartList = []
      for (let i = 0; i < quantity; i++) {
        cartList.push(cartItem)
      }
      this.replaceTemporaryCartList(cartList)
    },

    setEGift(_eGift: EGiftSelect): void {
      this.eGift = _eGift
    },

    resetEGift(): void {
      this.eGift = {
        message: "",
        giver_name: "",
        is_select: false,
        isAnonymous: false,
      }
    },

    setSocialSignUpReceiveMail(_socialSignUpReceiveMail: boolean): void {
      this.socialSignUpReceiveMail = _socialSignUpReceiveMail
    },

    setEGiftData(_eGiftData: EGiftWithOrderFragment): void {
      this.eGiftData = _eGiftData
    },

    resetGiftData(): void {
      this.eGiftData = {}
    },

    setEGiftOrderItems(_eGiftOrderItems: OrderItems[]): void {
      this.eGiftOrderItems = _eGiftOrderItems
    },

    resetEGiftOrderItems(): void {
      this.eGiftOrderItems = []
    },

    setEGiftShippingInfo(_eGiftShippingInfo: ShippingInfo): void {
      this.eGiftShippingInfo = {
        ..._eGiftShippingInfo,
        scheduled_shipping_date: _eGiftShippingInfo.scheduled_shipping_date ?? "",
      }
    },

    resetEGiftShippingInfo(): void {
      this.eGiftShippingInfo = {
        shipping_referred_type: "standard_delivery",
        shipping_referred_date: "",
        shipping_referred_time: "",
        scheduled_shipping_date: "",
      }
    },

    setEGiftSkuByProducts(_eGiftSkuByProducts: EGiftSkuByProduct): void {
      this.eGiftSkuByProducts = _eGiftSkuByProducts
    },

    resetEGiftSkuByProducts(): void {
      this.eGiftSkuByProducts = {
        eGift_id: "",
        skuByProducts: [],
      }
    },

    setOrderForm(_orderForm: OrderForm): void {
      const formData = cloneDeep(_orderForm)
      formData.password = ""
      this.orderForm = formData
    },

    setOrderFormCouponCode(_couponCode: string): void {
      this.orderForm.coupon_code = _couponCode
    },

    resetOrderForm() {
      this.orderForm = initOrderFormData()
    },

    resetRouteLogs(): void {
      this.routeLogs = []
    },

    addRouteLog(routeLog: RouteLog): void {
      if (this.routeLogs.length >= 100) {
        this.routeLogs = this.routeLogs.slice(-99)
      }
      this.routeLogs.push(routeLog)
    },

    sentEvent(): void {
      this.purchaseEventSent = true
    },

    resetSentEvent(): void {
      this.purchaseEventSent = false
    },

    addProductBrowsingHistory(product: Product): void {
      const newHistory = this.productBrowsingHistory.filter((prod) => prod.document_id !== product.document_id)
      if (newHistory.length > 25) {
        newHistory.pop()
      }
      newHistory.unshift(product)
      this.productBrowsingHistory = newHistory
    },

    setTrafficParams(referrer: string): void {
      this.trafficParams = {
        full_path: referrer,
      }
    },

    trackingPost(params: AdTrackingParams): void {
      this.trackingParams = {
        ...params,
      }
    },

    resetTracking(): void {
      this.trackingParams = {
        ad_url: "",
        to_url: "",
      }
    },

    resetTraffic(): void {
      this.trafficParams = {
        full_path: "",
      }
    },

    setUser(payload: UserPayload) {
      this.user.document_id = payload.document_id
      this.user.site_id = payload.site_id ?? null
      this.user.email = payload.email ?? ""
      this.user.token = payload.token ?? ""
      this.user.isSigned = payload.isSigned ?? false
      if (payload.data) this.user.data = payload.data
    },

    resetOut() {
      this.user = {
        isSigned: false,
        document_id: "",
        site_id: null,
        email: "",
        token: "",
        data: {},
      }
    },

    initializeSocialId() {
      this.socialId = ""
    },

    mergeOrder(_order: Record<string, unknown>) {
      this.order = {
        ...this.order,
        ..._order,
      }
    },

    setUsedCoupons(usedCoupons: UsedCouponType[]) {
      this.usedCoupons = usedCoupons
    },
    setHeader(headerRef: HTMLElement) {
      this.headerRef = String(headerRef)
    },

    setContact(contact: ContactStoreState) {
      this.contact = contact
    },
    setCouponCode(payload: { couponCode?: string; expired: number; myCoupon?: CouponWithUserCouponIdFragment }) {
      this.coupon = {
        couponCode: payload.couponCode || "",
        expired: payload.expired,
        myCoupon: payload.myCoupon || null,
      }
    },
    setDistributedCoupon(payload: { coupons: Omit<Coupon, "_id">[]; isNotModal?: boolean }) {
      this.distributedCoupon.coupons = payload.coupons
      this.distributedCoupon.isNotModal = !!payload.isNotModal
    },
    setReview(reviewForm: { review: ReviewCreateInput; confirm: boolean; isAnonymous: boolean }) {
      this.reviewForm = reviewForm
    },
    setReviewRecordId(recordId: string) {
      this.reviewForm.recordId = recordId
    },
    checkAndSetTemporaryCartList() {
      if (this.temporaryCartList.length >= 1) {
        this.stashCartList = this.cartList
        this.cartList = this.temporaryCartList
        this.initializeTemporaryCartList()
      }
    },
    resetTemporaryCartList() {
      this.initializeTemporaryCartList()
      if (this.stashCartList.length !== 0) {
        this.cartList = this.stashCartList
        this.stashCartList = []
      }
    },
    resetCartList() {
      this.cartList = []
    },
    resetContact() {
      this.contact = {
        channel: "",
        confirmEmail: "",
        content: "",
        email: "",
        image_urls: [],
        files: [],
        local_user_id: "",
        name: "",
        order_id: "",
        product_name: "",
        site_id: "",
        type_id: 0,
        type_name: "",
        category: "before_order",
        sub_category: "about_the_product",
        support_status: "",
        is_product_guide: false,
      }
    },
    resetOrder() {
      this.order = {
        document_id: "",
        total: 0,
        form: initOrderFormData(),
      }
    },
    resetDistributedCoupon() {
      this.distributedCoupon.coupons = []
      this.distributedCoupon.isNotModal = false
    },
    resetCoupon(): void {
      this.coupon = {
        couponCode: "",
        expired: 0,
        myCoupon: null,
      }
    },
    resetReview() {
      this.reviewForm = initReviewFormData()
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
})
